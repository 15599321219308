
<template>
  <div class="container">
    <!--    <h1>Vue2 控制视频播放示例</h1>-->
    <div v-sliden-in="{duration:800}" class="titleDesign">
      <div class="title" v-html="title">
      </div>
    </div>
    <div v-sliden-in="{duration:800}" class="videoDesign">
      <video ref="videoPlayer" class="videoPlayer"  controls>
        <source :src=videoPath type="video/mp4">
        您的浏览器不支持 HTML5 视频标签。
      </video>
    </div>

  </div>
</template>

<script>
import {vSlidenIn} from "@/utils/vSlidenIn";
export default {
  directives:{
    slidenIn:vSlidenIn
  },
  props: {
    videoPath: {
      type:String,
      default:'https://cn.oilgasgpts.com/static/media/AICP-%E6%B2%B9%E6%B0%94%E7%94%9F%E4%BA%A7%E6%99%BA%E8%83%BD%E7%AE%A1%E6%8E%A7%E5%B9%B3%E5%8F%B0-20230302.mp4'
    },
    title:{
      type:String,
      default: '<li>油气生产智能管控解决方案以井、场站数据自动化采集、视频监控为依托，充分利用云计算、大数据等新技术，实现油气生产动态实时感知、油气生产全流程监控、运行精准高效指挥等功能，满足全面感知、精准管控、超前预警、高效协同、智能优化、科学决策的油气生产管理需求。</li>'
    }


  },
  name: 'App',
  methods: {
  }
}
</script>

<style scoped>
.container{
  //display: flex;
  .videoDesign{
    //background-color: blue;
    //width: 50%;
    margin-top: 25px;
    display: flex;
    .videoPlayer{
      margin: auto;
      width: 100%;
    }
  }
  .titleDesign{
    //background-color: black;
    //width: 50%;
    margin-top: 25px;
    display: flex;
    //justify-content: center;

    //align-items: center;
    .title{
      width: 90%;
      height: fit-content;
      margin: auto;
      font-size: 16px;
      line-height: 23px;
      font-weight: bold;
      font-family: arial, sans-serif, "Microsoft Yahei";
    }
  }
}
h1 {
  text-align: center;
}
button {
  margin: 10px;
}
</style>
