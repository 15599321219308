<template>
  <div>
    <div v-if="isMobile === false">
      <div class="news">
        <transition appear name="expand">
          <div v-if="!$parent.homevisible">
<!--          <div v-if="focus">-->
            <el-button  style="margin-left: 1vw;" class="title3img" @click="focusback"  src=""></el-button>
          </div>
        </transition>
        <transition appear name="expand-height">
          <!--                <div class="talk_con animate__slow"  :style="{ height: focus ? '77.5vh' : '58vh'}">-->


          <div
              ref="talkCon"
              :class="{ 'expanded': focus }"
              :style="focus ? { height: '93vh' , background: 'rgb(80, 80, 80, 0.1);'} : { height: '93vh',  background: 'rgb(80, 80, 80, 0.1);' }"
              class="talk_con animate__animated animate__slow"
          >

            <div class="content"  :style="focus ? { height: '85%' } : { height: '85%',}"
            >
              <div ref="messageContainer" class="communicate">
                <div class="talk-show" style="margin-top: -0.5vh;width: 56%;margin: auto">
                  <div
                      v-for="message in messages"
                      :key="message.id"
                      :class="message.sender === 'user' ? 'user' : 'ai'"
                      class="message"
                  >
                    <!--                          用户-->
                    <div v-if="message.sender === 'user'">
                      <div class="btalk">
                        <div style="display: flex;justify-content: flex-end">
                          <div>
                          <span><div class="arrow-content">{{ message.text }}</div>
                          </span>
                          </div>
                          <div>
                            <img
                                :src=" avatar
                                "
                                alt="User Avatar"
                                class="userPhoto"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--                          AI回答-->
                    <div v-else>
                      <div class="atalk" v-if="message.introduce!=''">
                        <!--                              正在回答-->
                        <div v-if="message.ok===0" class="AIphotoMove"></div>
                        <!--                              回答结束-->
                        <div v-if="message.ok===1||message.ok===2" class="AIphoto"></div>
                        <div id="asay" class="answer">
                          <div id="whose1" class="talk">
                            <div v-if="message.title!=='nan'" style="font-weight: bold;font-size: 1vw;line-height: 4vh" v-html="message.title"></div>
<!--                            <div class="introduce" v-html="message.introduce"></div>-->
                            <vue-markdown class="introduce" v-if="message.introduce!==''" :source="message.introduce">

                            </vue-markdown>
                            <div v-if="(message.ok===1||message.ok===2)&&(JSON.stringify(message.url)!=='null'&&message.url!=='nan')" class="talkurl"
                                 @click="urlChange(message)"
                            >
                              <div v-if="message.language==='CN'" style="color: black;margin-top: 1vh">详情请参考：</div>
                              <div v-if="message.language==='EN'" style="color: black;margin-top: 1vh">The details are as follows:</div>
                              <div  v-html="message.url"></div>
                            </div>
                            <div v-if="message.isSendingMessage===2" class="stop" style="color: gray">已停止生成</div>
                          </div>
<!--                          <div v-if="message.isSendingMessage===1" class="stopMove"   @click="stopPrinting=true">停止生成</div>-->
                          <div v-if="message.isSendingMessage===1" class="stopMove"   @click="stopppp(message)">停止生成</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="talk_input">
<!--                <div class="hotGPT" >-->
<!--                  <div class="imgDesign">-->
<!--                    <img src="@/assets/hot.png" style="height: 2.5vh;width: 2.5vh">-->
<!--                  </div>-->
<!--                  <div class="titleDesign">-->
<!--                    热点：-->
<!--                    <div style="gap: 1vw;display: flex">-->
<!--                      <a target="_blank" href="/frack" class="title1">-->
<!--                        AI预测预计采收储量-->
<!--                      </a>-->
<!--                      <a target="_blank" href="/Stratum" class="title1">-->
<!--                        AI分析地质分层-->
<!--                      </a>-->
<!--                      <a target="_blank" href="/wisdom" class="title1">-->
<!--                        数据驱动决策平台-->
<!--                      </a>-->
<!--                      <a target="_blank" href="/station" class="title1">-->
<!--                        智慧场站-->
<!--                      </a>-->
<!--&lt;!&ndash;                      <a target="_blank" href="/security" class="title1">&ndash;&gt;-->
<!--&lt;!&ndash;                        智慧安防&ndash;&gt;-->
<!--&lt;!&ndash;                      </a>&ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
                <div ref="input_container" class="input_container">
<!--                  <div>-->
                    <textarea
                        ref="textnewArea"
                        id="text"
                        v-model="newMessage"
                        :rows="1"
                        class="talk_word"
                        maxlength="3000"
                        :placeholder=inputplaceholder
                        @input="resizeTextarea"
                        @focus="focusinput"
                        @blur="blurinput"
                        @keydown.enter.prevent
                        @keyup.enter="sendMessage(event)"
                        style="bottom: unset"
                    ></textarea>
<!--                  </div>-->
<!--                  <div class="talk_button">-->
                    <!--                  <el-button-->
                    <!--                      v-if="net===1"-->
                    <!--                      class="talk_web"-->
                    <!--                      disabled-->
                    <!--                      type="primary"-->
                    <!--                      @click="sendNet()"-->
                    <!--                  ></el-button>-->
                    <!--                  <el-button-->
                    <!--                      v-if="net===0"-->
                    <!--                      class="talk_webNo"-->
                    <!--                      disabled-->
                    <!--                      type="primary"-->
                    <!--                      @click="sendNet()"-->
                    <!--                  ></el-button>-->
                    <!--                          <el-button-->
                    <!--                              v-if="identification==='0'"-->
                    <!--                              :disabled="isSendingMessage"-->
                    <!--                              class="talk_file"-->
                    <!--                              type="primary"-->
                    <!--                              @click="showUpload()"-->
                    <!--                          ></el-button>-->
                    <el-button
                        class="talk_sub"
                        :disabled="stopPrinting"
                        type="primary"
                        @click="sendMessage()"
                        style="margin-top: unset;bottom: unset"
                    ></el-button>
<!--                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition appear name="expand">
          <div v-if="blur===1">
<!--            <el-button class="title4img" src="" @click="focus=0"></el-button>-->
            <div style="position: relative">
              <img src="@/assets/arrow.png" class="animated-element" style="width: 3vw;height: 3vh;position: absolute">
              <img src="@/assets/arrow.png" class="animated-element2" style="width: 3vw;height: 3vh;position: absolute">
              <img src="@/assets/arrow.png" class="animated-element3" style="width: 3vw;height: 3vh;position: absolute">
              <img src="@/assets/arrow.png" class="animated-element4" style="width: 3vw;height: 3vh;position: absolute">
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import {getAuthorize} from "@/api/user";
import {shareUrl} from "@/utils/vxshare.js";
import VueMarkdown from "vue-markdown/src/VueMarkdown";
export default {
  name: "AIAnswer",
  props: {
    input: {
      type: Number,
      required: true
    },
    inputplaceholder: {
      type: String,
      default: ''
    },
  },
  components: {VueMarkdown},
  data() {
    return {
      inputplaceholdertemp:this.inputplaceholder,
      disableEnter:false,
      changeVisable: 1,
      dialogVisibleInfo: false,
      registerIn: false,
      forgetIn: false,
      loginIn: false,
      dialogVisible: false,
      isMobile: false,
      titleEn: "",
      contentEn: "",
      languageSelect: "",
      isClicked: false,
      userInfo: "",
      isLogin: "",
      isNEWs: 0,
      isTextDisplayed: false,
      isSendingMessage: true,
      identification: 0,
      messages: [{
        ok: 0,
        id: '00000',
        sender: "ai",
        introduce: "",
        // introduce: "您好，这里您可获取油气行业全方位的人工智能应用服务，今天您需要什么协助？\n",
        resource: "",
        url:'nan',
        isSendingMessage: 0,
        language:''
      },
      ],
      newMessage: "",
      focus: 0,
      blur:1,
      stopPrinting:false,
      Message: "",
      token: "",
      baseURL: this.$utils.baseURL,
      avatar: require("@/assets/user.jpg"),
      userId: "",
      communicateHistory: [],
      denyformShow: false,
      denyForm: {
        id: "",
        question: "",
        answer: "",
      },
      primary: "",
      submitForm: {
        id: "",
        question: "",
        answer: "",
        primary: "",
      },
      textarea: "",
      deny: 0,
      count: 0,
      uniqueId: "",
      question: "",
      answer: "",
      options: [
        {
          value: "1",
          label: "新建对话",
        },
        {
          value: "2",
          label: "历史记录",
        },
      ],
      optionvalue: "1",
      showPopover: false,
      timer: null,
      articles: [],
      answerAIID:0
    };
  },
  methods: {
    async SSEinite() {
      const self = this
      // this.$axios.get("http://localhost:25145/").then((response) => {
      this.$axios.get("/chatinfo").then((response) => {
        // this.$axios.get("http://localhost:25145/").then((response) => {
        this.talkid = response.data["id"]
        // alert(this.talkid)

        // this.eventSource = new EventSource('http://localhost:25145/stream?channel=' + response.data["id"]);
         const eventSource = new EventSource('/chatinfo/stream?channel=' + response.data["id"]);
          eventSource.addEventListener('message_event', (event) => {
          var data = JSON.parse(event.data);
          console.log("服务器说: " + data.message);
          console.log("服务器说:stopPrinting " + this.stopPrinting);
          if(data.message!=="" && data.message.slice(0,6)!=="--stop" ){
            // 正在输入
            // this.stopPrinting=true
            // this.stopPrinting=false
          }
          else{
            this.stopPrinting=false
            // this.isSendingMessage=1
          }
          if (!this.messages) {
            this.messages = [];
          }
          const index = this.messages.findIndex(
              (message) => message.id === this.answerAIID && message.sender === "ai"
          );
          if (index !== -1) {
            // // 逐字显示回答
            // this.$set(this.messages[index], "id", 12366); // 更改ID
            // this.$set(this.messages[index], "answer", data.message); // 更改ID

            if(this.messages[index].introduce==="正在生成，请稍候..."){
              this.messages[index].introduce=""
            }
            if(data.message!=="" ){
              console.log(this.stopPrinting)
              if(data.message.slice(0,6)!=="--stop" && this.stopPrinting===true){
                // 正在输入
                this.$set(this.messages[index], "introduce", this.messages[index].introduce + data.message);

              }
              else if(data.message.slice(0,6)==="--stop"){
                // 支持输入
                this.$set(this.messages[index], "isSendingMessage", 0);
                this.stopPrinting=false
              }
              // 正在输入

            }

            // vm.printAnswerMessage('resource', vm, index);
            // vm.$set(vm.messages[index], 'resource', resindex);
            this.$nextTick(() => {
              // 在下一个tick时滚动到底部
              const container = this.$refs.messageContainer;
              container.scrollTop = container.scrollHeight;
            });
            // console.log("huidu ")
            // console.log(vm.messages)
          }
          self.answer = data.message

        }, false);
          eventSource.onerror = () =>{
            console.log("发生错误了！！！！！！！！！！！！！！")
            // let tempid = this.talkid

          }
        if (this.$route.query.question !== undefined && this.$route.query.question!== ''){
          this.newMessage= this.$route.query.question;
          this.sendMessage()
        }

      })


      /*      this.eventSource.onmessage = (event) => {
              print("123123213123123")
              const data = JSON.parse(event.data)//将字符串转换成json格式
              if (data.number !== 'done') {
                this.answer.push(data.number);
              } else {
                this.eventSource.close();
              }
            };

            this.eventSource.onerror = (error) => {
              if (this.eventSource.readyState === EventSource.CLOSED) {
                console.log('Connection to server closed');
              } else {
                console.error('Error occurred:', error);
              }
            };*/
    },
    // 在组件中触发动画
    triggerSequentialAnimations() {
      const elements = document.querySelectorAll('.grand2'); // 获取所有需要触发动画的元素
      let promiseChain = Promise.resolve(); // 初始为 resolved 状态
      elements.forEach((el, index) => {
        promiseChain = promiseChain.then(() => {
          return new Promise((resolve) => {
            // 使用 v-sliden-in 指令触发动画并等待动画完成
            const animation = el.animate(
                [
                  { transform: `translateY(20px)`, opacity: 0 },
                  { transform: 'translateY(0)', opacity: 1 }
                ], {
                  duration: 600 + index * 200,  // 每个模块有不同的时长
                  easing: 'ease-in-out',
                  delay: 0,
                  fill: 'forwards',
                }
            );
            // 等待动画完成后再执行下一个动画
            animation.finished.then(resolve);
          });
        });
      });
    },
    // async SSEinite() {
    //   const maxRetries = 5;  // 最大重连次数
    //   let retries = 0;        // 当前重连次数
    //   let eventSource = null;
    //
    //   // 自动重连逻辑
    //   const connectSSE = () => {
    //     if (eventSource) {
    //       eventSource.close();
    //     }
    //     eventSource = new EventSource(`/chatinfo/stream?channel=${this.talkid}`);
    //     eventSource.onopen = () => {
    //       console.log('✅ SSE 连接成功');
    //       retries = 0;  // 重置重连次数
    //     };
    //
    //     eventSource.onmessage = (event) => {
    //       try {
    //         const data = JSON.parse(event.data);
    //         console.log("📩 服务器消息:", data.message);
    //
    //         if (data.message && data.message.slice(0, 6) !== "--stop") {
    //           this.stopPrinting = true;
    //         } else {
    //           this.stopPrinting = false;
    //         }
    //
    //         const index = this.messages.findIndex(
    //             (message) => message.id === this.answerAIID && message.sender === "ai"
    //         );
    //
    //         if (index !== -1) {
    //           if (this.messages[index].introduce === "正在生成，请稍候...") {
    //             this.messages[index].introduce = "";
    //           }
    //
    //           if (data.message) {
    //             if (data.message.slice(0, 6) !== "--stop" && this.stopPrinting) {
    //               // 拼接新消息
    //               this.messages[index].introduce += data.message;
    //             } else if (data.message.slice(0, 6) === "--stop") {
    //               this.messages[index].isSendingMessage = 0;
    //               this.stopPrinting = false;
    //             }
    //           }
    //
    //           // 滚动到底部
    //           this.$nextTick(() => {
    //             const container = this.$refs.messageContainer;
    //             if (container) {
    //               container.scrollTop = container.scrollHeight;
    //             }
    //           });
    //         }
    //
    //         this.answer = data.message;
    //
    //       } catch (error) {
    //         console.error('❌ SSE 数据解析失败:', error);
    //       }
    //     };
    //
    //     // 自动重连机制
    //     eventSource.onerror = () => {
    //       console.error(`⚠️ SSE 连接失败: 第 ${retries + 1} 次重试`);
    //       eventSource.close();
    //
    //       if (retries < maxRetries) {
    //         retries++;
    //         setTimeout(connectSSE, 2000 * retries);  // 指数退避重连
    //       } else {
    //         console.error("🚫 SSE 重连失败，请刷新页面");
    //       }
    //     };
    //   };
    //
    //   // 初始化 SSE 连接
    //   try {
    //     const response = await this.$axios.get("/chatinfo");
    //     this.talkid = response.data["id"];
    //
    //     // 启动 SSE 连接
    //     connectSSE();
    //
    //     // 检查是否有查询参数自动发送消息
    //     if (this.$route.query.question) {
    //       this.newMessage = this.$route.query.question;
    //       await this.sendMessage();
    //     }
    //
    //   } catch (error) {
    //     console.error('❌ SSE 初始化失败:', error);
    //   }
    // },
    focusback(){
      this.$emit("focusback",true)
      document.body.style.overflowY='auto'
    },
    setMessage(event){
      this.newMessage=event
      this.sendMessage()
    },
    urlChange(msg) {
      // console.log(msg.title==="企业管理GPTs")
      if (msg.url === "https://cn.oilgasgpts.com/") {
        switch (msg.title) {
          case "油气资讯GPTs":
            this.$emit("skipping", 1);
            break;
          case "油气专业GPTs":
            this.$emit("skipping", 2);
            break;
          case "企业管理GPTs":
            this.$emit("skipping", 3);
            console.log("tiaozhuan");
            break;
          case "通用类GPTs":
            this.$emit("skipping", 4);
            console.log("tiaozhuan");
            break;
          case "油气开发作业GPT应用服务":
            this.$emit("skipping", 5);
            break;
          case "数智油田解决方案GPT应用服务":
            this.$emit("skipping", 6);
            break;
          case "智能多媒体服务":
            this.$emit("skipping", 7);
            break;
          case "人工智能全员普及培训及转型咨询服务":
            this.$emit("skipping", 8);
            break;
          case "油气企业自建GPT云平台服务":
            this.$emit("skipping", 9);
            break;
          case "安迅数智服务":
            this.$emit("skipping", 10);
            break;
          case "安迅数智":
            this.$emit("skipping", 10);
            break;
          case "石同多媒体":
            this.$emit("skipping", 10);
            break;
          default:
            console.log("未找到匹配的标题");
            break;
        }
      } else {
        window.open(msg.url);
      }

    },
    changeheadVisable() {
      if (this.changeVisable === 1) {
        this.changeVisable = 0
      } else {
        this.changeVisable = 1
      }
      console.log("父组件", this.changeVisable)
    },
    pushLogin() {
      let href = window.location.href.split("&code")[0];
      getAuthorize(href).then((res) => {
        window.location.replace(res.data.url);
      });
      // this.$router.push("/login")
      // this.$emit("pushLogin")
    },
    handleClose() {
      this.denyformShow = true;
    },
    showDeleteButton(historyItem) {
      // 在鼠标悬停时显示删除按钮
      this.$set(historyItem, "showDeleteButton", true);
    },
    hideDeleteButton(historyItem) {
      // 在鼠标移出时隐藏删除按钮
      this.$set(historyItem, "showDeleteButton", false);
    },
    showDialog(type) {
      this.dialogVisible = type;
    },
    optionnew(item) {
      console.log("Selected value:", item.value);
    },
    dateIfAddZero(time) {
      return time < 10 ? "0" + time : time;
    },
    // 文章索引
    clickRobot(item) {
      this.isNEWs = 1;
      this.$router.push({
        path: "/articalDetail",
        query: {
          Typeid: item.primaryTypeid,
          newId: item.id,
        },
      });
      this.$axios
          .get("/api/news/" + item.id, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            this.articles = res.data.data;
            const currentDate = new Date(this.articles.createTime);
            const year = currentDate.getFullYear();
            let month = this.dateIfAddZero(currentDate.getMonth() + 1);
            let day = this.dateIfAddZero(currentDate.getDate());
            const formattedDate = `${year}-${month}-${day}`;
            this.articles.dateTime = formattedDate;
          })
          .catch((error) => {
            console.log(error);
          });

      var vm = this;
      vm.$axios
          .post("/systemenes/trans", {
            // params: {
            newsId: item.id,
            // },
            headers: {
              Authorization: vm.token,
            },
          })
          .then((res) => {
            // console.log(res.data.data.enTitle)
            vm.titleEn = res.data.data.enTitle;
            vm.contentEn = res.data.data.enContent;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    // 新建对话
    // newContent() {
    //   this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
    //   this.messages = [];
    //   this.count = 0;
    //   this.isSendingMessage = false;
    //
    //   if (this.languageSelect === "zh") {
    //     this.$message.warning({
    //       title: "提示",
    //       message: "新建对话成功，我们将重新开始新一轮对话。",
    //       showClose: false,
    //       customClass: "notify-success",
    //     });
    //   } else {
    //     this.$message.warning({
    //       title: "Tips",
    //       message:
    //           "If the new dialogue is successful, we will start a new round of dialogue",
    //       showClose: false,
    //       customClass: "notify-success",
    //     });
    //   }
    // },
    // // 历史记录轮次
    // historylist() {
    //   // console.log("ddd")
    //   // console.log(parseInt(this.userId))
    //   this.$axios
    //     .get("api/qa/selectAll", {
    //       params: {
    //         userId: this.userId,
    //       },
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: parseInt(this.userId),
    //       },
    //     })
    //     .then((res) => {
    //       this.communicateHistory = res.data;
    //       for (var i = 0; i < this.communicateHistory.length; i++) {
    //         const currentDate = new Date(this.communicateHistory[i].createTime);
    //         const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    //         const day = ("0" + currentDate.getDate()).slice(-2);
    //         const hours = ("0" + currentDate.getHours()).slice(-2);
    //         const min = ("0" + currentDate.getMinutes()).slice(-2);
    //         const formattedDate = `${month}-${day} ${hours}:${min}`;
    //         this.communicateHistory[i].createTime = formattedDate;
    //         this.communicateHistory[i].isClicked = false;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    // 删除历史
    deleteHistory(item) {
      console.log(item.roundId);

      console.log("删除");
    },
    // 详细的历史记录
    HisCommunicate(communicateHistory) {
      for (var i = 0; i < this.communicateHistory.length; i++) {
        this.communicateHistory[i].isClicked = false;
      }

      communicateHistory.isClicked = true;
      console.log(communicateHistory);
      this.$axios
          .get("/api/qa/selectByRoundId/" + communicateHistory.roundId, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            this.isTextDisplayed = true;
            this.messages = [];
            for (var i = 0; i < res.data.length; i++) {
              this.uniqueId = communicateHistory.roundId;
              this.messages.push({
                id: res.data[i].id,
                sender: "user",
                text: res.data[i].qaQuery,
              });
              if (res.data[i].qaEvaluate) {
                res.data[i].qaEvaluate = 1;
              } else {
                res.data[i].qaEvaluate = 0;
              }
              this.messages.push({
                // 使用保存的vm
                id: res.data[i].id,
                sender: "ai",
                introduce: res.data[i].qaAnswers,
                deny: res.data[i].qaEvaluate,
                question: res.data[i].qaQuery,
              });
            }
          });
    },
    // 点否提交
    submitDeny() {
      this.submitForm.answer = this.denyForm.answer;
      this.denyformShow = false;
      console.log(this.denyForm);
      console.log(this.submitForm);
      // 假设您有一个要更新的 message 对象
      this.messages = this.messages.map((message) => {
        if (message.id === this.submitForm.id) {
          message.deny = 1; // 将匹配条件下的 message 的 deny 属性设置为 1
        }
        return message; // 返回更新后的 message
      });

      console.log(this.messages); // 打印更新后的 messages 数组

      this.$axios
          .put(
              "/api/qa/update",
              {
                id: this.submitForm.id,
                qaEvaluationContent: this.denyForm.answer,
                qaAnswers: this.denyForm.answer,
                qaQuery: this.submitForm.primary,
                qaEvaluate: 1,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: this.token,
                },
              }
          )
          .then((res) => {
            console.log(res);
          });
    },
    // 点否重置
    resetDeny() {
      this.denyForm.question = this.submitForm.question;
      this.denyForm.answer = this.submitForm.primary;
    },
    changeWho() {
      this.showPopover = false;
    },
    resizeTextarea(event) {
      event.preventDefault();
      const textarea = document.getElementById('text');
      textarea.style.height = 'auto'; // 重置高度，使其自动调整
      if(this.isMobile===false){
        console.log("---鼠标奥杜",textarea.scrollHeight)
        textarea.style.height = (textarea.scrollHeight - 20) + 'px'; // 设置为内容高度
        if (this.newMessage.trim() === '') {

          textarea.style.height = '4vh'; // 当内容为空时，设置为最小高度

        }
      }
      else{
        textarea.style.height = (textarea.scrollHeight-16) + 'px'; // 设置为内容高度
        if (this.newMessage.trim() === '') {

          console.log("kongde ")
          textarea.style.height = '5vh'; // 当内容为空时，设置为最小高度

        }
      }


      textarea.scrollTop = 0; // 将滚动条置顶
      if (this.newMessage.length >= 3000) {
        this.$emit('input', this.newMessage.slice(0, 3000));
        this.$message.warning(`最多可以输入3000个字符`);
      }
    },
//     sendMessage($event) {
//       this.focus = 1
//       this.blur = 0
//       this.$emit("setTop", 1)
//       if(this.isSendingMessage===true){
//         this.stopPrinting=false
//
//         const startTime = new Date();
//         if(this.isMobile === false){
//           this.$emit("setTop", 1)
//         }
//         const textarea = document.getElementById('text');
//         textarea.style.height = 'auto'; // 重置高度，使其自动调整
//         textarea.style.height = textarea.scrollHeight + 'px'; // 设置为内容高度
//         textarea.style.height = '12px'; // 当内容为空时，设置为最小高度
//         // 找到所有满足条件的消息
//         const messagesToUpdate = this.messages.filter(
//             (message) => message.id === "00000" && message.sender === "ai" && message.ok === 0
//         );
//
// // 遍历所有找到的消息并更新它们的ok属性
//         messagesToUpdate.forEach((message) => {
//           this.$set(message, "ok", 2);
//         });
//
//         // 找到所有满足条件的消息
//         const mes = this.messages.filter(
//             (message) => message.id === "11111" && message.sender === "ai" && message.ok === 0
//         );
//
// // 遍历所有找到的消息并更新它们的ok属性
//         mes.forEach((message) => {
//           this.$set(message, "ok", 2);
//         });
//
//
//         // 清除之前的定时器
//         if (this.timer) {
//           clearTimeout(this.timer);
//         }
//         this.timer = setTimeout(() => {
//           this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
//
//           this.messages = [
//             {
//               ok: 0,
//               id: '00000',
//               sender: "ai",
//               introduce: "您好，这里您可获取油气行业全方位的人工智能应用服务，今天您需要什么协助？",
//               resource: "",
//               url:'nan',
//               isSendingMessage: 0,
//             },
//           ];
//           this.count = 0;
//
//           // this.historylist();
//           this.$message.warning({
//             title: "提示",
//             message: "当前超出10min未进行对话，请刷新重试",
//             showClose: true,
//           });
//         }, 600000); // 等于 60,000*10 毫秒
//
//
//         if (this.uniqueId === "") {
//           this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
//         }
//
//         console.log("输入", this.newMessage)
//
//         if(this.newMessage.trim() === "")
//         {
//           return
//           // this.question =this.inputplaceholder
//           // console.log("--------------默认判断-------",this.question)
//         }
//         else{
//           // 问题
//           this.question = this.newMessage;
//         }
//
//         // if (this.newMessage.trim() !== "") {
//
//           // 出现暂停键
//           this.isSendingMessage = false;
//
//           this.count++;
//
//           this.answerAIID++;
//           console.log("--------------默认-------",this.question)
//           // push进入数组
//           this.messages.push({
//             id: this.uniqueId,
//             sender: "user",
//             text: this.question,
//           });
//
//           // 先添加“稍后再试”消息
//           this.messages.push({
//             id: "",
//             ok: 0,
//             sender: "ai",
//             introduce: "正在生成，请稍候...",
//             isSendingMessage: 1,
//             title: "",
//             url: "",
//             evaluationI: "0",
//             copy: 0,
//             question: this.question,
//             language:''
//           });
//
//           // 对话超过21次
//           if (this.count === 21) {
//             this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
//             this.messages = [];
//             this.count = 0;
//             this.isSendingMessage = true;
//             // this.historylist();
//             this.$message.warning({
//               title: "提示",
//               message:
//                   "当前对话轮次超出小安的能力限制啦，我们将重新开始新一轮对话。",
//               showClose: false,
//             });
//           }
//           // 正常对话
//           else {
//             // 输入框为空
//             this.newMessage = "";
//
//             var vm = this; // 保存this
//
//             this.$axios
//                 .get(
//                     // "/search/askQuestion?question=" + this.question,
//                     "https://cn.oilgasgpts.com/AQ/search/askQuestionD?question=" + this.question,
//                     {
//                       headers: {
//                         "Content-Type": "application/json",
//                         Authorization: this.token,
//                       },
//                     }
//                 )
//                 .then(async function (response) {
//                   // 记录结束时间
//                   const endTime = new Date();
//
//                   // 计算执行时间（以毫秒为单位）
//                   const executionTime = endTime - startTime;
//
//                   // 输出执行时间
//                   console.log(`${executionTime} 毫秒`);
//                   // console.log(response.data.data)
//                   var resindex = [];
//                   for (var i = 0; i < response.data.data.introduce.length; i++) {
//                     resindex.push({
//                       index: i + 1,
//                       title: response.data.data.introduce[i],
//                     });
//                   }
//                   vm.isSendingMessage = false;
//                   // 查找索引以替换“稍后再试”消息
//                   const index = vm.messages.findIndex(
//                       (message) => message.id === "" && message.sender === "ai"
//                   );
//                   // 若存在这样的信息
//                   if (index !== -1) {
//                     // 逐字显示回答
//                     vm.$set(vm.messages[index], "id", response.data.id); // 更改ID
//                     // 主子输出
//                     vm.$set(vm.messages[index], "title", response.data.data.title);
//                     vm.$set(vm.messages[index], 'url', response.data.data.url);
//                     vm.$set(vm.messages[index], 'language', response.data.data.language);
//
//                     // console.log(vm.messages[index].url==="null")
//                     // console.log("-----------")
//                     // console.log(vm.messages[index].url==="'nan")
//                     var introduce = response.data.data.introduce
//                     await vm.printAnswerMessage(
//                         introduce,
//                         vm,
//                         index,
//                         resindex
//                     );
//
//                     this.$nextTick(() => {
//                       // 在下一个tick时滚动到底部
//                       const container = this.$refs.messageContainer;
//                       container.scrollTop = container.scrollHeight;
//                     });
//                     // console.log("huidu ")
//                     // console.log(vm.messages)
//                   }
//                 });
//             // 发送给后端
//             // setTimeout(() => {
//             //
//             // }, 2000); // 2000毫秒（2秒）
//           }
//         // } else {
//         //   // this.$message.warning({
//         //   //   title: "提示",
//         //   //   message: "请输入你的问题",
//         //   //   showClose: false,
//         //   // });
//         // }
//
//         this.$nextTick(() => {
//           const container = this.$refs.messageContainer;
//           container.scrollTop = container.scrollHeight;
//         });
//       } else {
//         this.$message.warning({
//           title: "提示",
//           message: "正在生成中，请稍候",
//           showClose: false,
//         });
//         $event.preventDefault();
//         console.log("禁止发送")
//       }
//
//     },
    stopppp(message)
    {
      this.stopPrinting=false
      console.log("--------------------false")
      this.$set(message, "isSendingMessage", 2); // 更改isSendingMessage样式、
      this.$axios.post("/chatinfo/stop",{
        talkid:this.talkid
      })
    },


    sendMessage() {
      console.log("正在输入1sendMessage: ", this.stopPrinting);
      if ( this.stopPrinting===false){
        this.stopPrinting=true
        this.answerAIID++
        this.messages.push({
          // id: this.uniqueId,
          sender: "user",
          text: this.newMessage,
        });
        console.log("进来了p---------------------")
        const temptext=this.newMessage
        this.newMessage=""
        //       // 先添加“稍后再试”消息
        this.messages.push({
          id: this.answerAIID,
          ok: 0,
          sender: "ai",
          introduce: "正在生成，请稍候...",
          isSendingMessage: 1,
          title: "",
          url: "",
          evaluationI: "0",
          copy: 0,
          question: this.question,
          language:'',
        });
        let languageUrl=''
        // if(self.$i18n.locale == 'zh'){
        languageUrl="/chatinfo/get_answer2G"
        // }else {
        //   languageUrl="/chatinfo/get_answerEn2"
        // }
        this.$axios.post(languageUrl, {
          // this.$axios.post("/chatinfo/get_answerEn2", {
          // this.$axios.post("/chatinfo/get_answer2", {
          question: temptext,
          client_id: this.talkid
        }).then((res)=>{
          console.log(res)
          this.$nextTick(() => {
            // 在下一个tick时滚动到底部
            const container = this.$refs.messageContainer;
            container.scrollTop = container.scrollHeight;
          });
        })

      }
      else{
        this.$notify.warning({
          title: "提示",
          message: "正在生成",
          showClose: true,
        })
      }



    },
    focusinput() {
      let elemtn1=document.getElementById('text')
      elemtn1.placeholder=""
      this.focus = 1
      this.blur = 0
      this.$emit("setTop", 1)
      // console.log("获取到焦点")
    },
    blurinput() {
      let elemtn1=document.getElementById('text')
      elemtn1.placeholder=this.inputplaceholdertemp
      this.blur = 1
    },

    // async printAnswerMessage(message, vm, index, resindex) {
    //   let currentIndex = 0;
    //   let printedMessage = "";
    //
    //   const printFunction = () => {
    //     // 输出
    //     if (!this.stopPrinting && currentIndex < message.length) {
    //
    //       printedMessage += message[currentIndex];
    //       vm.messages[index].introduce = printedMessage;
    //       currentIndex++;
    //       setTimeout(() => {
    //         if (!this.stopPrinting) {
    //           printFunction();
    //         } else {
    //           console.log("stoooooooooooooop")
    //           this.stopPrinting = false
    //           vm.$set(vm.messages[index], "recourse", resindex); // 更改
    //           vm.$set(vm.messages[index], "ok", 1); // 更改图表样式、
    //           this.isSendingMessage = 1
    //           vm.$set(vm.messages[index], "isSendingMessage", 2);
    //           console.log(vm.messages)
    //         }
    //         this.$nextTick(() => {
    //           const container = this.$refs.messageContainer;
    //           container.scrollTop = container.scrollHeight;
    //         });
    //       }, 25);
    //
    //     }
    //     // 输出结束
    //     else {
    //       vm.$set(vm.messages[index], "recourse", resindex); // 更改ID
    //       vm.$set(vm.messages[index], "ok", 1); // 更改图表样式
    //       // 当消息逐字显示完成后，设置 isTextDisplayed 为 true
    //       this.isTextDisplayed = true;
    //
    //       if (this.stopPrinting === true) {
    //         vm.$set(vm.messages[index], "isSendingMessage", 2);
    //       } else {
    //         vm.$set(vm.messages[index], "isSendingMessage", 0);
    //       }
    //       this.isSendingMessage = 1;
    //       this.$nextTick(() => {
    //         const container = this.$refs.messageContainer;
    //         container.scrollTop = container.scrollHeight;
    //       });
    //     }
    //   };
    //   printFunction();
    // },
    // 复制
    copy(message) {
      if (message.copy === 0) {
        message.copy = 1
      }
      // console.log(message)
      const textToCopy = message.introduce;
      const el = document.createElement('textarea');
      el.value = textToCopy;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    },
    // 点赞
    gray(message) {
      console.log(message.evaluationI)
      console.log("message")
      console.log(message)
      if (message.evaluationI === "1") {

        this.$axios.post(
            // "http://11.89.147.1:8080/system/dialogue/external_evaluation",
            "http://localhost:8080/system/dialogue/internal_evaluation",
            {
              id: message.id,
              evaluationI: "0",
            },
            {
              headers: {
                "Content-Type": "application/json",
                // Authorization: this.token,
              },
            }
        ).then((res) => {
          this.getNo()
          this.getAppreciate()
          this.$set(message, "evaluationI", "0"); // 更改ID
          console.log(res);
        });


      } else {

        // console.log("end")
        // console.log(message.evaluationI)
        this.$axios
            .post(
                // "http://11.89.147.1:8080/system/dialogue/external_evaluation",
                "http://localhost:8080/system/dialogue/internal_evaluation",
                {
                  id: message.id,
                  evaluationI: "1",
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    // Authorization: this.token,
                  },
                }
            ).then((res) => {
          this.$set(message, "evaluationI", "1"); // 更改ID
          this.getNo()
          this.getAppreciate()
          console.log(res);
        });
      }
    },
    // 点否点击
    denyChange(event) {
      // 获取按钮自带的 data-info 参数
      if (event.deny === 0) {
        this.denyformShow = true;
        this.submitForm.question = event.question;
        this.submitForm.answer = event.answer;
        this.submitForm.primary = event.answer;
        this.submitForm.id = event.id;
        this.denyForm.question = this.submitForm.question;
        this.denyForm.answer = this.submitForm.primary;
      } else {
        this.messages = this.messages.map((message) => {
          if (message.id === this.submitForm.id) {
            message.deny = 0; // 将匹配条件下的 message 的 deny 属性设置为 1
          }
          return message; // 返回更新后的 message
        });

        console.log(this.messages); // 打印更新后的 messages 数组
      }
    },
  },
  beforeDestroy() {
    const eventSource = new EventSource('/chatinfo/stream?channel=' + this.talkid);
    eventSource.removeEventListener('message_event')
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(this.userInfo.avatar !== null);
    this.token = localStorage.getItem("access_token");
    this.userId = localStorage.getItem("userId");
    // this.token = "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImI0ODQ4ZGRlLTg4ODEtNGFhNy1iNzRmLTgwZTM5NmMxZmU2YyJ9.LMY_3ane52i9K_vJ3NaBeF5rkMx-jCSLPZI2rFClTkqOVAT3amV1O-FJkZD4BHJyo1wOv-J6K-eDdKzdCyF6UQ",

    //  this.userId =1

    // this.historylist();
    let url = window.location.href;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "油气人才",
        text: "全球油气行业专业人才求职招募平台，AI辅助简历优化，在线雇主及时沟通，更多油气行业岗位，快来油气人才吧！",
      };
      shareUrl(shareData);
    }, 1000);
  },
  mounted() {
    // this.triggerSequentialAnimations();
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
  watch: {
    optionvalue(newValue) {
      // console.log("Selected value:", newValue);
      if (newValue === "1") {
        this.newContent();
      }

      // 在这里可以处理选项变化后的逻辑
    },
    stopPrinting(newValue) {
      // console.log("Selected value:", newValue);
      if (newValue === true) {
        this.newContent();
      }

      // 在这里可以处理选项变化后的逻辑
    },
    input(newValue) {
      console.log("Selected value:", newValue === 0);

      if (newValue === 0) {
        this.focus = 0
      }

    },
  },
};
</script>

<style lang='scss' scoped>
li {
  /* 取消列表项的标记 */
  list-style-type: none;
  /* 或者使用 list-style: none; */
  /* list-style: none; */
}
@keyframes jiantou {
  0% {

    transform: scale(0.2);
    margin-top: -6vh;
    opacity: 0.3;
  }
  50%{

    transform: scale(0.5);
    margin-top: -4vh;
    opacity:1;
  }
  100% {

    transform: scale(0.2);
    margin-top: -2vh;
    opacity: 0.3;
  }
}
.animated-element{
  //height: 10vh;

  animation: jiantou 3s linear infinite 0S;
}
.animated-element2{
  //height: 10vh;

  animation: jiantou 3s linear infinite 0.8s;
}
.animated-element3{
  //height: 10vh;

  animation: jiantou 3s linear infinite 2s;
}
.animated-element4{
  //height: 10vh;
  animation: jiantou 3s linear infinite 3s;
}


.expand-enter-active {
  animation: bounceIn 5s linear both;
}

.expand-height-enter-active {
  transition: height 3s ease;
}

.expand-height-enter-from,
.expand-height-leave-to {
  height: 58vh;
}

.expanded {
  height: 75vh;
  overflow: hidden;
}

.notify-custom-class {
  /* 在这里放置自定义通知样式 */
  background-color: #f0ad4e; /* 设置背景颜色为黄色 */
  color: #fff; /* 设置文本颜色为白色 */
  font-size: 16px; /* 设置字体大小 */
  /* 添加其他样式设置 */
}

.notLogin {
  text-align: center;
  color: #666666;
  font-size: 16px;
  display: flex;
  align-content: center;
  flex-direction: column;
  padding: 100px 0px;

  img {
    width: 306px;
    margin: 0 auto;
  }
}

.buttons-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 95%;
  display: flex;
  justify-content: flex-start;
}

.deleteStyle {
  display: none; /* 初始状态下隐藏删除按钮 */
}

.historyCard:hover .deleteStyle {
  margin-top: 5%;
  margin-left: 40px;
  display: inline-block; /* 鼠标悬停在.historyCard上时显示删除按钮 */
}

.questioninput {
  //overflow: auto; /* 添加滚动条 */
  max-height: 8vh;
  width: 32vw;
  font-size: 16px;
  height: auto;
  border-radius: 5px;
  padding: 5px;
  margin-left: 5px;
  border: black 2px solid;

  .el-textarea__inner {
    height: 5vh;
  }
}

.ansinput {
  width: 32vw;
  font-size: 16px;
  margin-left: 6px;
  height: auto;
  border-radius: 5px;
  padding: 5px;
  min-height: 13vh;
  max-height: 13vh;
  overflow: auto; /* 添加滚动条 */
}

::v-deep .el-dialog__headerbtn {
  cursor: pointer;
  font-size: 25px;
  font-weight: 800;
}

.phone {
  position: fixed;

  ::v-deep {
    .el-dialog {
      margin: auto !important;
      font-size: 14px;
      top: 35vh;
    }

    .el-dialog__header {
      padding: 5px;
      border-bottom: 1px solid #ebebeb;

      .el-dialog__title {
        font-weight: bold;
        padding: 15px;
        font-size: 18px;
      }
    }

    .el-dialog__body {
      padding: 10px 15px;
      color: #606266;
      font-size: 14px;
      word-break: break-all;
    }

    .el-button {
      width: 105px;
      height: 28px;
      font-size: 14px;
      line-height: 28px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      border: 1px solid var(--main-color);
      padding: 0;

      a {
        display: block;
      }
    }
  }
}

::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #ebebeb;

    .el-dialog__title {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .el-button {
    width: 100px;
    height: 28px;
    line-height: 28px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    padding: 0;

    a {
      display: block;
    }
  }

  .el-button--primary.is-disabled, .el-button--primary.is-disabled:active, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:hover {
    background-color: transparent;
    position: absolute;
    width: 6vh;
    height: 4vh;
    float: left;
    margin-top: -4vh;
    bottom: 4vh;
    right: 0;
    padding: 8px 10px;
    border: none;
    border-radius: 5px;
    background-image: url("../assets/sendno.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    //animation: pulseno 2s infinite;
  }

  @keyframes pulseno {
    0% {
      transform: scale(1); /* 原始大小 */
    }
    50% {
      transform: scale(1.2); /* 放大到120% */
    }
    100% {
      transform: scale(1); /* 恢复到原始大小 */
    }
  }

  .el-button--primary {
    color: #fff;
  }

  .el-button--primary:focus, .el-button--primary:hover {
    background-color: white;
    position: absolute;
    cursor: pointer; /* 鼠标变成手型 */
    width: 6vh;
    height: 4vh;
    float: left;
    margin-top: -4vh;
    bottom: 4.2vh;
    right: 0;
    padding: 8px 10px;
    //margin-left: 58%;
    //margin-right: 2vw;
    border: none;
    border-radius: 5px;
    background-image: url("../assets/send.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    //background-color: transparent;
  }

}

.fload {
  width: 15px;
  height: 75vh;
  background-image: url("../assets/fload.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--main-color);
}

::v-deep .el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close {
  color: #f8f8f8 !important;
}

::v-deep .el-notification__group {
  /* 修改宽度和字体 */
  width: 250px !important; /* 设置宽度为 300px */
  font-size: 8px; /* 设置字体大小为 16px */
}

::v-deep el-dialog__close el-icon el-icon-close {
  color: white;
  background-image: url("../assets/exit.png") !important;
}

::v-deep .el-dialog__headerbtn {
  position: absolute;
  top: 0px;
  right: 20px;
  padding: 0;
  border: none;
  outline: 0;
  color: white;
  background-image: url("../assets/exit.png") !important;
  cursor: pointer;
  font-size: 25px;
}

::v-deep .el-dialog {
  position: relative;
  margin: 0px !important;
  background: #fff;
  border-radius: 2px;
  box-shadow: 8px 5px 8px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  width: 100%;
}

::v-deep .el-dialog__body {
  padding: 0;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}

::v-deep .el-dialog__header {
  padding: 0;
}

.con_que {
  color: #1c88ff;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}

.czkj-question-msg {
  float: left;
  font-size: 14px;
  color: #3163c5;
}

.horizontal-line2 {
  margin-top: 2vh;
  width: 88%; /* 设置分界线的宽度 */
  height: 1px; /* 设置分界线的高度 */
  margin-left: 5%;
  text-align: center;
  color: gray;
  background-color: rgba(128, 128, 128, 0.2); /* 设置分界线的颜色 */
}

.denyForm {
  overflow-y: hidden;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  background-color: white;
  width: 48vw;
  height: 48vh;
  border-radius: 5px;
  position: absolute;
  padding-bottom: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.deny {
  border: none;
  margin-left: auto;
  position: absolute;
  display: block;
  cursor: pointer;
  background-image: url("../assets/deny.png");
  background-color: transparent;
  width: 25px;
  height: 25px;
  margin-top: auto;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  right: -30px;
  background-position: center center;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  50% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  100% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
}

// .deny:hover {
// //animation: shake 0.5s; //animation-iteration-count: 2; transform: scale(1.2); /* 放大图片 */
// }

// .denyReally:hover {
// //animation: shake 0.5s; //animation-iteration-count: 2; transform: scale(0.8); /* 放大图片 */
// }

.denyReally {
  border: none;
  background-color: #f7f7f9;
  margin-left: auto;
  position: absolute;
  display: block;
  cursor: pointer;
  background-image: url("../assets/denyReally.png");
  width: 25px;
  height: 25px;
  margin-top: auto;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: -25px;
  right: 10px;
  background-position: center center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white !important;
  align-content: center;
}

.loading-message {
  display: inline-block;
  background-color: rgba(255, 255, 255);
  border-radius: 5px;
  color: #000;
  width: 100%;
  padding: 5px 10px;
  word-wrap: break-word;
  position: relative; /* Add this */
}

.hover-text {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  min-width: 100px;
  text-align: center;
  font-size: 14px;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -160%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

li {
  list-style-type: none;
}

.historycontend {
  border: none;
  font-size: 2vh;
  font-weight: bold;
  min-height: 54vh;
  max-height: 54vh;
  height: auto;
  overflow-x: hidden;
  text-align: center; /* 文本水平居中对齐 */
  bottom: 0;
  width: 90%;
  margin: 2vh 1vh 2vh 2vh;
}

.historyCard {
  height: 58px;
  border-radius: 5px;
  border: rgba(44, 43, 43, 0.2) 2px solid;
  margin-bottom: 5px;
  width: 98%;
  display: flex;
}

.historyCard:hover {
  color: var(--main-color);
  font-size: 16px;
}

.active {
  color: var(--main-color);
  font-size: 14px;
  background-color: rgba(139, 0, 0, 0.1);
  border: var(--main-color) 2px solid;
}

table.no-border {
  border-collapse: collapse !important;
  border: none !important;
}

/* 自定义滚动条样式 */
.historycontend::-webkit-scrollbar {
  width: 1vh; /* 滚动条宽度 */
}

.historycontend::-webkit-scrollbar-track {
  background-color: rgb(236, 184, 192);
  border-radius: 3vh;
}

.historycontend::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
  border-radius: 3vh;
}

::v-deep .Historyimg {
  padding-left: 5px;
  height: 32px;
  width: 32px;
  border: none;
  background-image: url("../assets/comelogo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.HistoryCon {
  margin: 8px 2px 0 2px;
  width: 125px;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
}

.historyTime {
  margin: 8px 2px 0 2px;
  width: 90px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.historyCard:hover .historyTime {
  display: none;
}

.hover-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px; /* 箭头宽度的一半 */
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.deny:hover .hover-text {
  display: block;
}

.newhistory {
  margin: 1vw;
  width: 87%;
  height: 5vh;
  border: 5px solid #be0022;
  background-color: #be0022;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  justify-content: center; /* 水平居中对齐 */
  text-align: center; /* 文本水平居中对齐 */
  border-radius: 25px;
}

.clock {
  margin: 1vw;
  width: 87%;
  height: 5vh;
  background-color: #fdf8f8;
  color: #bababa;
  font-size: 18px;
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  justify-content: center; /* 水平居中对齐 */
  text-align: center; /* 文本水平居中对齐 */
  border-radius: 25px;
}

/*小图标样式位置**/
.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  color: rgb(86, 215, 255);
}

/*更换背景颜色**/
::v-deep .el-input__wrapper {
  font-size: 25px;
  width: 88px;
  height: 88px;
  background: url("../assets/button.png") 50% center / cover no-repeat;
}

::v-deep .el-select .el-input__inner {
  border: none !important;
  color: #fff !important;
  font-size: 18px;
  width: 100px;
  height: 22px;
}

::v-deep .el-select-dropdown__item {
  font-size: 8px !important;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  cursor: pointer;
}

::v-deep .el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 20px;
}

::v-deep .el-input {
  font-size: 8px;
}

/*小图标隐藏**/

::v-deep .el-input__inner::placeholder {
  color: #fff;
  font-size: 14px;
}

::v-deep .el-input__wrapper {
  box-shadow: none !important;
}

.newlogo {
  background-image: url("../assets/new.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  text-align: center;
  position: relative;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.newClock {
  background-image: url("../assets/clock.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  text-align: center;
  position: relative;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}


.timeStyle {
  font-size: 12px;
  color: #2c2b2b;
  margin-right: 12px;
}

.communicate::-webkit-scrollbar-track-piece {
  background-color: #f8f8f800;
}

.communicate::-webkit-scrollbar {
  width: 6px;
  transition: all 2s;
}

.communicate::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  border-radius: 100px;
}

.communicate::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}

.communicate::-webkit-scrollbar-corner {
  background-color: rgba(255, 255, 255, 0);
}

.fade-enter-active,
.fade-leave-active {
  transition: height 2s linear 0s;
}

.fade-enter-to,
.fade-leave-from {
  height: 75vh;
}

.fade-enter-from,
.fade-leave-to {
  height: 55vh;
  overflow: hidden;
}

.news {
  position: relative;
  //background-color: #2f6dfd;
  height: fit-content;
}

.title3img{
  position: absolute;
  top:1vh;
  cursor: pointer;
  height: 1.5vh;
  width: 1.5vw;
  left: 0%;
  transform: translateX(-50%);
  animation: pulse 2s infinite;
  background-image: url("../assets/back1.png");
  //background-image: url("../assets/left_white.png");
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
}

.title3img :hover {
  background-color: transparent;
}

.title4img {
  position: absolute;
  bottom: 0;
  cursor: pointer;
  height: 5.5vh;
  width: 2vw;
  left: 48%;
  transform: translateX(-50%);
  animation: down 2s infinite;
  background-image: url("../assets/colordown.png");
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
}

.title4img:hover {
  background-color: transparent !important;
}

@keyframes down {
  0% {
    transform: translateY(-0.5vh); /* 原始大小 */
  }
  50% {
    transform: translateY(0.5vh); /* 原始大小 */
  }
  100% {
    transform: translateY(-0.5vh); /* 原始大小 */
  }
}

@keyframes pulse {
  0% {
    transform: translateY(0.5vh); /* 原始大小 */
  }

  100% {
    transform: translateY(0); /* 原始大小 */
  }
}

.talk_con {
  transition: height 1s ease;
  background-color: rgb(80, 80, 80, 0.1);

  //background: linear-gradient(180deg, #ebeff7, #e6ebf7);
  //background: rgba(240, 76, 76, 0.1);
  border-radius: 5px;
  height: 58vh;
  //position: absolute;
  //width: 67vw;
  width: 100%;
  //left: 50%;
  //transform: translateX(-50%);
  text-align: center;
  margin: 1vh auto 0 auto;
  //border: #1c0101 5px solid;

}

.talk_show {
  width: 60%;
  height: 56vh;
  margin: 0 19% 0 19%;
  padding-top: 2%;
  //border:black 2px solid;
  /* overflow:auto; */
  overflow-y: scroll;
  /* overflow: hidden; */
}

.content {
  background-color: transparent;
  //width: 63vw;
  width: 100%;
  height: 85%;
  border-radius: 5px;
  //border: 5px solid black;
  .tip {
    margin-top: 15px;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 25px;
  }

  .tips {
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    background: linear-gradient(to right, rgb(230, 250, 250) 0%, #f1e4d3 100%);
    height: fit-content;
    padding: 15px;

    .circle {
      width: 20px;
      height: 20px;
      background-image: url("../assets/Circle.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent; /* 这里设置背景颜色为透明 */
    }

    .highlight {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      margin: -2px 10px;
    }

    .title {
      font-size: 14px;
      font-weight: bold;
      color: #666666;
      margin: 10px 0 0 0;
      text-align: justify;
    }
  }
}

.communicate {
  height: 100%;
  width: 100%;
  //width: 61vw;
  padding: 0 1vw;
  margin: 1.5vh auto 0 auto;
  background: transparent;
  font-size: 16px;
  //font-size: 1.1vw;
  overflow-y: auto;
  overflow-x: hidden;
  //border: 5px solid black;
}

//.arrow-content {
//  padding: 10px;
//  margin:5px 1% auto auto;
//  position: relative;
//  display: inline-block;
//  text-align: left;
//  color: #000;
//  border-radius: 5px;
//  background-color: rgba(46, 128, 5, 0.6);
//}
.triangle1 {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 8px 8px; /* 调整边框宽度，0表示无顶部边框 */
  border-color: transparent transparent transparent rgba(46, 128, 5, 0.6); /* 调整边框颜色 */
  top: 15px; /* 控制三角形位置 */
  right: -14px; /* 控制三角形位置 */
  transform: translateY(-50%); /* 让三角形垂直居中 */
}

.right {
  text-align: left;
}

.talk_sub {
  position: absolute;
  cursor: pointer; /* 鼠标变成手型 */
  width: 6vh;
  height: 4vh;
  float: left;
  margin-top: -4vh;
  bottom: 4vh;
  right: 0;
  padding: 8px 10px;
  //margin-left: 58%;
  //margin-right: 2vw;
  border: none;
  border-radius: 5px;
  background-image: url("../assets/send.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
}

.talk_sub2 {
  background-color: transparent;
  position: absolute;
  width: 6vh;
  height: 4vh;
  float: left;
  margin-top: -4vh;
  bottom: 4vh;
  right: 0;
  padding: 8px 10px;
  border: none;
  border-radius: 5px;
  background-image: url("../assets/sendno.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  animation: pulseno 2s infinite;
}

.talk_sub:hover {
  transform: scale(1.1);
  cursor: pointer; /* 鼠标变成手型 */
  border: none;
  border-radius: 5px;
  background-image: url("../assets/send.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
}

.talk_sub2:hover {
  transform: scale(1.1);
  cursor: pointer; /* 鼠标变成手型 */
  border: none;
  border-radius: 5px;
  background-image: url("../assets/sendno.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
}

.atalk {
  margin: 1.5vh 1.5vh 1.5vh 0;
  padding: 0.5vh 0.5vh 0.5vh 0;
  display: flex;
  //background-color: white;
  position: relative;
  align-items: flex-start; /* Align items at the top */
}

.btalk {
  margin: 2px;
  right: 0 !important;
  display: flex;
  flex-direction: column;
  flex-grow: 8;
}

.AIphotoMove {

  background-image: url("../assets/AIphoto.png");
  min-width: 4vh;
  height: 4vh;
  margin-right: 0.5vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 20%;
  margin-top: 0.5vh;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pulse 2s infinite;
}

.stopMove {
  text-align: left;
  cursor: pointer;
  margin: 1vh 1.5vw;
  font-size: 0.7vw;
  color: #7b88f2;
}

.stop {
  text-align: left;
  cursor: pointer;
  margin: 0.5vh 0 0 0;
  font-size: 0.7vw;
  color: gray;

}

.stopMove:hover {
  font-weight: bold;

}

@keyframes pulse {
  0% {
    transform: scale(1); /* 原始大小 */
  }
  50% {
    transform: scale(1.2); /* 放大到120% */
  }
  100% {
    transform: scale(1); /* 恢复到原始大小 */
  }
}

.AIphoto {
  margin-top: 0.5vh;
  background-image: url("../assets/AIphotoNo.png");
  min-width: 4vh;
  height: 4vh;
  margin-right: 0.5vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 20%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

}

.userPhoto {
  //background-image: url("../assets/userPhoto.png"); min-width: 30px; margin-top: 3px; position: relative;
  width: 4vh;
  min-width: 4vh;
  height: 4vh;
  margin-left: 0.5vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  margin-top: 0.8vh;
}

.talk {
  display: inline-block;
  background-color: white;
  border-radius: 9px;
  color: #000;
  text-align: left;
  //width: 90%;
  margin: 0 2vw auto 0.5vw;
  padding: 1vh 1vw 1vh 2vw;
  line-height: 2;
  word-wrap: break-word;
  position: relative; /* Add this */
  .introduce {

    height: auto;
    width: 100%;
    //max-height: 12vh;

  }

  .talkurl {
    color: #FF6400;;
    cursor: pointer;
    width: fit-content;
    //display: flex;

  }

  .talkurl:hover {
    width: fit-content;
    border-bottom: 1px solid #FF6400;;
  }
}

/* 添加尖嘴（三角形） */
.triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 8px 8px; /* 调整边框宽度，0表示无顶部边框 */
  border-color: transparent #fffcfc transparent transparent; /* 调整边框颜色 */
  top: 15px; /* 控制三角形位置 */
  left: -15px; /* 控制三角形位置 */
  transform: translateY(-50%); /* 让三角形垂直居中 */
}

.talk_word {
  //min-height: 120px;
  height: fit-content;
  padding: 1vh 0vw 1vh 0.5vw;
  overflow: auto;
  font-size: 0.9vw;
  position: absolute;
  outline: none;
  text-align: justify;
  width: 100%;
  bottom: 3vh;
  float: left;
  text-indent: 10px;
  //box-shadow: 2px -6px 0 2px #f8f8f8;
  border: rgba(44, 62, 80, 0.1) 1px solid;
  background-color: white; /* 半透明白色，alpha值为0.5 */
  border-radius: 9px !important;
  resize: vertical; /* 允许垂直调整大小 */
  min-height: 4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  max-height: 200px;
  font-style: normal;
  font-weight: 400;
  line-height: 4vh;
  //letter-spacing: 0.1vh;

}

.select {
  background-image: url("../assets/button.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 45px;
  height: 45px;
  margin-top: auto;
  bottom: 5px;
  border: none;
}

.select1 {
  width: 25px;
  height: 20px;
  margin-top: auto;
  bottom: 0px;
  border: none;
}

::v-deep .talkcontent .el-input__inner {
  width: 100%;
  background-color: #e8e8e8;
  text-align: center;
  border-color: #c0c4cc;
  color: #000;
}

::v-deep .el-textarea .el-input__count {
  color: #909399;
  background: #fff;
  position: absolute;
  font-size: 18px;
  bottom: 5px;
  left: 10px;
}

::v-deep .el-textarea__inner {
  border: none !important;
  font-size: 18px !important;
  height: 86px;
  width: 98%;
}

.talkcontent {
  margin-left: 1%;
  min-height: 82px;
  white-space: pre-wrap; /* 使文本根据内容自动换行 */
  outline: none;
  width: 98%;
  max-height: 82px;
  padding: 3px;
  float: left;
  text-indent: 10px;
  border-radius: 5px;
  overflow: auto;
  //border: black 5px solid; background-color: white; /* 半透明白色，alpha值为0.5 */
}


.arrow-content {
  background-color: #0267ff;
  border-radius: 5px;
  color: white;
  text-align: left;
  margin: 8px 5px auto auto;
  padding: 0.5vh 1vw;
  //display: inline-block;
  display: flex;
  align-items: center;
  min-height: 1.5vw;
  font-size: 16px;
  //font-size: 1.1vw;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5vw;
  letter-spacing: 0.5px;
}

.title {
  color: black;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.whotalk {
  min-height: 88px;
  outline: none;
  max-height: 25px;
  padding: 5px;
  width: 80% !important;
  border-radius: 5px;
  border: none;
  //border-right: black 1px solid; width: 10%;
}

.talk_input {
  margin-bottom: 4vh;
  overflow: visible;
  //display: flex; /* 启用Flexbox布局 */
  flex-direction: column; /* 子元素垂直排列 */
  align-items: center; /* 拉伸子元素以填充容器 */
  justify-content: center;;
  left: 50%;
  transform: translateX(-50%);
  height: fit-content;
  border-radius: 5px;
  background-color: transparent;
  position: absolute;
  bottom: 0;
  width: 55%;
  .hotGPT{
    height: 4vh;display: flex;padding: 0.5vh;
    .imgDesign{
      margin-left: 1vw

    }
    .titleDesign{
      font-size:0.9vw;font-weight: bolder;display: flex;align-items: center;margin-left: 0.5vw;
      .title1{
        background-color: rgb(255, 255, 255);border-radius: 9px;padding: 0.3vw;font-size: 0.8vw;color: #122277;
        font-weight: 400;

      }
    }
  }
}

.input_container {
  min-height: 6vh;
  display: flex; /* 使用 Flexbox 布局 */
  align-items: center; /* 垂直居中 */
  width: 100%;
}

.talk_button {
  display: flex;
  justify-content: space-between; /* 子元素之间的间隔平均分布 */
  cursor: pointer; /* 鼠标变成手型 */
  width: fit-content;

  padding: 15px;
  margin: auto 0 0 0;
}

</style>

