<script>
export default {
  data(){
    return{
      textarea2:''
    }
  },
  methods:{
    msSend1(){
      console.log("12312312321")
      if(this.textarea2!=''){
        this.$emit("msSend1",this.textarea2)
      }
      // this.$emit("msSend1",this.textarea2)
    },
    NavigatTo(event){
      this.$parent.NavigatTo(event)
    },
    toinfo(){
      window.open('https://cn.oilgasinfoai.com/')
    }
  }

}
</script>

<template>
<div class="NaviContainer" >
  <div style="width: 100%;height: fit-content;display: flex">
    <div style="width: 78%;height: fit-content;position: relative;margin: auto">
      <el-input style="margin-top: 4vh;border-radius: 15px" :autosize="{ minRows: 1, maxRows: 9}" type="textarea" v-model="textarea2" resize="none" placeholder="发送您要咨询的问题">

      </el-input>
<!--      <img  @click="msSend1" style="width: 9vw;height: 9vw;position: absolute;bottom: 1.5vw;right: 1.5vw" src="@/assets/homeSend.png">-->
      <div @click="msSend1" style="border-radius: 9px;width: 11vw;height:9vw;background: linear-gradient(to right, #117DD3, rgba(245,92,82,0.8));position: absolute;bottom: 1.5vw;right: 1.5vw;display: flex">
        <img style="height: 7vw;width: 7vw;margin: auto" src="@/assets/up_arrow_white.png">
      </div>
    </div>
  </div>

  <div class="N"  style=" height: 33vw;width: 78%;margin: auto;display: flex;grid-template-columns: minmax(0,1fr);margin-top: 5vh">
    <div  style="display: flex;width: 70%" >
<!--      <div style="width: 100%;height: 100%;display: flex">-->
<!--        <div style="width: 100%;height: 50%;display: flex;align-items: center">-->
<!--            <div class="icon" style=";width: 30px;height: 30px;display: flex;background-color: rgba(140, 147, 157, 0.5);border: 2px solid #666d7d;border-radius: 9px;">-->
<!--              <img style="width: 25px;height: 25px;margin: auto" src="@/assets/vector3.png">-->
<!--            </div>-->
<!--            <div class="icon" style="margin-left: 5px;width: 30px;height: 30px;display: flex;background-color: rgba(140, 147, 157, 0.5);border: 2px solid #666d7d;border-radius: 9px;">-->
<!--              <img style="width: 25px;height: 25px;margin: auto" src="@/assets/vector2.png">-->
<!--            </div>-->
<!--            <div class="icon" style="margin-left: 5px;width: 30px;height: 30px;display: flex;background-color: rgb(58,159,251);border: 2px solid #666d7d;border-radius: 9px;">-->
<!--              <img style="width: 25px;height: 25px;margin: auto" src="@/assets/vector1.png">-->
<!--            </div>-->
<!--            <div class="icon" style="margin-left: 5px;width: 30px;height: 30px;display: flex;background-color: rgba(140, 147, 157, 0.5);border: 2px solid #666d7d;border-radius: 9px;">-->
<!--              <img style="width: 25px;height: 25px;margin: auto" src="@/assets/Vector.png">-->
<!--            </div>-->
<!--          <img style="height: 5vh;width: 5vh;position:absolute;right: 1vw;top: 1vw" src="@/assets/right_circle.png">-->
<!--        </div>-->
        <div style=";font-size:4vw;margin: auto;font-family: Sora;height: 100%;width: 80%;align-items: center;justify-content: space-between;display: flex;flex-direction: column;padding: 3vw 0 5vw;">
          <span style="color: white;text-align: center;line-height: 1.5;font-size: 4.2vw;font-weight: bolder">
           洞察油气行业前沿资讯把握市场先机
          </span>
          <div style="background-color: #fdfdfd;padding: 2vw 8vw;border-radius: 9px;color: #000000;position: relative" @click="toinfo()">
            获取新闻资讯
            <img style="position: absolute;top: 50%;transform: translateY(-50%);right: 2vw;height: 3vw;width: 3vw" src="@/assets/more_btn_icon.png_black.png">
          </div>
        </div>
<!--      </div>-->
<!--      <div style="position: absolute;right: 4vw;top: 50%;transform: translateY(-50%)">-->
<!--        <img style="width: 4vw;height: 4vw" src="@/assets/more_btn_icon.png_black.png">-->
<!--      </div>-->
    </div>
      <div style="margin: auto 0 auto 2vw;justify-content: space-between;width: 30%;color: white;font-weight: bolder;display: flex;flex-direction: column;">
        <img style="width: 8vh;height: 8vh;margin:1vw auto" src="@/assets/wechat.jpg">
        <div style="margin: auto">即刻关注<br>限时免费</div>
      </div>
<!--      <div style="font-family: Sora;margin: auto 0 auto 3vw;text-align: center;line-height: 4vw">-->
<!--        Follow Us!<br>Free Use!-->
<!--      </div>-->

  </div>

  <div class="naviBox" style="height: fit-content;width:78%;margin: 8vh auto 0 ">
    <!--              <div style="display: flex;height: 100%;width: 26vw;align-content: center;align-items: center">-->
    <!--                <img style="height: 9vw;width: 9vw" src="@/assets/homebanner3.png">-->
    <!--                <div style="font-weight: bolder" ><span style="color: #117cd2">Anvi</span><span style="color: #f0382a">sion</span><br>Services</div>-->
    <!--              </div>-->
    <div style="display: flex">
      <div class="parallelogram" style="border-bottom: 2px solid #117dd3;"></div>
      <div style="padding: 0 1vw;border-bottom: 2px solid #117dd3;font-size: 15px">安迅产品及服务</div>
    </div>
    <div style="height: 100%;width: 100%;display: grid;gap: 3vw;grid-template-columns: minmax(0,1fr);margin-top: 2vh">
      <div style="display: grid;gap: 2vw;grid-template-columns: minmax(0,1fr) minmax(0,1fr);height: fit-content">
        <div class="item" style="height: 100%" @click="NavigatTo('station')">
          <div style="display: flex">
            <img style="height: 4vw;width: 4vw;padding-right: 3vw" src="@/assets/news111.png">
            智慧油田
          </div>

          <img style="height: 9px;width: 9px" src="@/assets/more_btn_icon.png_black.png">
<!--          <div class="content2">-->
<!--            获取全场景的数智油田解决方案-->
<!--          </div>-->
        </div>
        <div class="item" style="height: 100%" @click="NavigatTo('professional')">
          <img style="height: 4vw;width: 4vw" src="@/assets/news112.png">
          定制化人工智能
          <img style="height: 9px;width: 9px" src="@/assets/more_btn_icon.png_black.png">
<!--          <div class="content2">-->
<!--            定制基于人工智能场景的油气行业应用-->
<!--          </div>-->
        </div>
      </div>
      <div style="display: grid;gap: 2vw;grid-template-columns: minmax(0,1fr) minmax(0,1fr)">
        <div class="item" style="height: 100%" @click="NavigatTo('software')">
          <div style="display:flex;">
            <img style="height: 4vw;width: 4vw;margin-right: 3vw" src="@/assets/news113.png">
            软件产品
          </div>

          <img style="height: 9px;width: 9px" src="@/assets/more_btn_icon.png_black.png">
<!--          <div class="content2">-->
<!--            全场景的数智油田软件和产品开发-->
<!--          </div>-->
        </div>
        <div class="item" style="height: 100%" @click="NavigatTo('media')">
          <div style="display:flex;">
            <img style="height: 4vw;width: 4vw;margin-right: 3vw" src="@/assets/news114.png">
            智能多媒体
          </div>
          <img style="height: 9px;width: 9px" src="@/assets/more_btn_icon.png_black.png">
<!--          <div cl -->
        </div>
      </div>

    </div>
  </div>

</div>
</template>

<style scoped lang="scss">
.parallelogram {
  width: 7vw;
  height: 4vw;
  background-color: #117dd3;
  transform: skew(20deg);
  //margin: 50px auto;
}
.NaviContainer{
  width: 100%;
  height:fit-content;

  .item{
    display: flex;
    justify-content: space-between;
    font-family: Sora;
    font-weight: bolder;
    background-color: rgb(255 255 255 / 30%);
    //background-color: rgba(80, 80, 80, 0.3);
    border: 1px solid gray;
    margin: 0.5vw 0.5vw;
    font-size: 3vw;
    color: black;
    //color: white;
    text-overflow: ellipsis;
    width: 100%;
    height: fit-content;
    border-radius: 9px;
    padding: 1vh 2vw ;
    //justify-content: center;
    text-align: left;
    //display: flex;
    align-items: center;
    .content2{
      line-height:1.4;
      margin-top: 1vh;
      font-size: 2vw;
      color: gray;
    }
  }
  .N{
    display: flex;
    position: relative;
    height: 33vw;
    //background-color: white;
    //margin: 0vh 10vw;
    margin-top: 4vh;
    //padding: 0 6vw;
    border-radius: 9px;
    border: 1px gray solid;
    background-color: rgb(255 255 255 / 30%);
    background-image: url("../../assets/Frame1171277103.png");
    //background-color: rgba(80, 80, 80, 0.3);
    //background-color: #117cd1;
    background-size: cover;
    text-align: left;
    color: black;
    font-weight: bolder;
    font-size: 3vw;
    .title1{
      font-size: 6vw;
      margin-top: 4vh;
      color: #6262f0;
    }
    .title2{
      font-weight: normal;
      font-size: 4vw;
      margin-top: 2vh;
      line-height: 6vw;
      color: black;
    }

  }
  #N2{
    background-image: url("../../assets/homebanner2.jpg");
  }
  ::v-deep .el-textarea__inner{
    //background: rgba(80, 80, 80, 0.3);
    background: rgb(255 255 255 / 30%);
    padding: 13vw 10vw 13vw 4vw !important;
    border-radius: 15px !important;
    color: black;
    border-color: #122277;
    //color: white;
  }
}

</style>
