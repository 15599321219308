import { render, staticRenderFns } from "./homebox.vue?vue&type=template&id=085f2114&scoped=true"
import script from "./homebox.vue?vue&type=script&lang=js"
export * from "./homebox.vue?vue&type=script&lang=js"
import style0 from "./homebox.vue?vue&type=style&index=0&id=085f2114&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "085f2114",
  null
  
)

export default component.exports