<template>

  <div class="up"  >
<!--    <div class="uptop"   @click="uptop"></div>-->
<!--    <div style="height: 1px;background-color: rgba(128,128,128,0.3);width: 100%"></div>-->
    <div @click="upkefu" style="padding: 0 0 1vh 0">
      <div  class="upkefu" ></div>
      <div style="font-size: 0.7vw;text-align: center;margin-top: 0.5vh;color: white"> 在线<br>客服</div>
    </div>
    <div style="height: 1px;background-color: rgba(128,128,128,0.3);width: 100%"></div>
    <div class="feedback"   @click="feedback">服务<br>反馈</div>
    <div style="height: 1px;background-color: rgba(128,128,128,0.3);width: 100%"></div>
<!--    <div class="wechaticon" @mouseenter="showTooltip=true"-->
<!--         @mouseleave="showTooltip=false" style="height: 6vh;width: 3vw;display: flex;justify-content: center;text-align: center;padding:1vh 1vw 0.5vh 0.3vw">-->
<!--      <svg t="1727166040965" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3334" width="100%" height="30">-->
<!--        <path d="M1010.8 628c0-141.2-141.3-256.2-299.9-256.2-168 0-300.3 115.1-300.3 256.2 0 141.4 132.3 256.2 300.3 256.2 35.2 0 70.7-8.9 106-17.7l96.8 53-26.6-88.2c70.9-53.2 123.7-123.7 123.7-203.3zM618 588.8c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40c0 22-17.9 40-40 40z m194.3-0.3c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40z" fill="#00C800" p-id="3335"></path><path d="M366.3 106.9c-194.1 0-353.1 132.3-353.1 300.3 0 97 52.9 176.6 141.3 238.4l-35.3 106.2 123.4-61.9c44.2 8.7 79.6 17.7 123.7 17.7 11.1 0 22.1-0.5 33-1.4-6.9-23.6-10.9-48.3-10.9-74 0-154.3 132.5-279.5 300.2-279.5 11.5 0 22.8 0.8 34 2.1C692 212.6 539.9 106.9 366.3 106.9zM247.7 349.2c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z m246.6 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z" fill="#00C800" p-id="3336"></path></svg>-->
<!--    </div>-->
<!--    <div class="tooltip"-->
<!--         v-if="showTooltip"-->
<!--         style="width: 15vh;height: 15vh;position: fixed;right: 2.5vw;top: 55vh;">-->
<!--      <img src="../assets/wechat.jpg"/>-->
<!--    </div>-->

  </div>

</template>


<script>

export default {

  name: "",

  props: [],

  components: {},

  data() {
    return {
      showTooltip:false,
      changeVisable:1,}

  },

  methods: {
    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    upkefu(){
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3451460111799764&webPageTitle=在线咨询")
    },
    upkefu2(){
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3452726083343114&webPageTitle=在线咨询")
    },
    uptop(){
      this.$emit("toptop",1)
    },
    feedback(){
      window.open("/feedback")
      // window.open("https://cnmall.antonoil.com/gpts_feedback", "_blank");
    },
  },

  mounted() {


  }

}

</script>


<style type="text/scss" lang="scss" scoped>


.up {
  z-index: 99;
  cursor: pointer;
  position: fixed;
  top: 40%;
  right: 0;
  padding: 5px;
  width: 3vw;
  height: fit-content;;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  background-color: #282d7e;

  //border-radius: 50%;
  box-shadow: 5px 0 14px 0px #D4E4FF;

  .uptop {
    width: 2vw;
    height: 2vw;

    margin: 1vh auto;
    background-image: url("../assets/up.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .upkefu {
    margin: 0.5vh auto;
    width: 2vw;
    height: 2vw;
    background-image: url("../assets/kefu2.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .feedback {
    padding: 0.5vh 0;
    margin: 1vh auto;
    font-size: 0.8vw;
    text-align: center;
    color: white;
  }

}
</style>
