<script>
// import {vSlidenIn} from "@/utils/vSlidenIn";
export default {
  directives:{
    // slidenIn:vSlidenIn
  },
  props:{
    imagepath:{
      type:String,
      default:require('@/assets/u19.png')
    },
    title2:{
      type:String,
      default:"帮助辅助效率提升，减少和盐成本，单位可视化提高数据可读性"
    },
    title1:{
      type:String,
      default:""
    },
    imageDesign_Height:{
      type:String,
      default:'45px'
    },
    imageDesign_Width:{
      type:String,
      default:'50px'
    },
    isicon:{
      type:Boolean,
      default:true
    }
  },
  computed:{
    dynamicClass(){
      let sult="";
      switch (this.isicon) {
        case 0:
          sult='card2';
          break;
        case 1:
          sult='card';
          break;
        case 2:
          sult='card3';
          break;
        default:
          sult='card';
      }
      return sult;
    },
  },
  data(){
    return{
      padw:{
        default:'0'
      }
    }
  }
}
</script>

<template>
  <div  :class="dynamicClass" :style="padw==0?{}:{'padding-left':padw+'vw','padding-right':padw+'vw'}">
    <div class="back">
      <div class="imgDesign" :style="{height: imageDesign_Height, width: imageDesign_Width}">
        <img loading="lazy"  style="border-radius: 9px" :src="imagepath">
      </div>
      <div class="titleDesign">
        <div class="title1" v-html="title1">
        </div>
        <div class="title2" v-html="title2">
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.card {
  border-radius: 9px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: #ebf1fd;
  padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
  opacity: 1;
  .back {
    margin-top: 15px;
    border-radius: 9px;
    position: relative;
    //height:580px ;
    overflow: hidden;

    .imgDesign {
      width: 50px;
      height: 45px;
      margin: 0 auto;
      opacity: 1;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
    }
    .titleDesign{
      .title1{
        font-size: 16px;
        font-weight: bolder;
        text-align: center;
        margin: 10px 10px 0 10px;
      }
      .title2{
        margin: 5px 10px;
        line-height: 22px;
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
      }
    }


  }

}
.card2 {
  border-radius: 9px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: #ebf1fd;
  padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
  opacity: 1;
  .back {
    margin-top: 15px;
    border-radius: 9px;
    position: relative;
    overflow: hidden;
    .imgDesign {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      opacity: 1;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
    }
    .titleDesign{
      .title1{
        font-size: 16px;
        font-weight: bolder;
        text-align: center;
        margin: 10px 10px 0 10px;
      }
      .title2{
        margin: 5px 10px;
        line-height: 22px;
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
      }
    }
  }
}
.card3{
  //cursor: pointer;
  width: 100%;
  height:100% ;
  padding:0.98887515451174vh 3.69324090121317vw;
  opacity: 1;

  .back{
    background: white;
    //border-radius: 9px;
    position: relative;
    //box-shadow: 2px 0 12px 0px #c9d9f5;
    height:100% ;
    overflow:hidden;
    border-radius: 27px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    background: linear-gradient(to right, white, #bad5ed, white);
    .imgDesign{
      width: 100%;
      height: 0vh !important;
      object-fit: cover;
      opacity: 1;
      //margin:3vh auto;
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
    }
    .titleDesign{
      padding: 2.5248454883vh 1.5vw 2.5248454883vh 1.5vw;
      margin:0 auto;
      color: black;
      width:100%;
      float: right;
      .title1{
        margin:2vh 0;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
      }
      .title2{
        line-height: 3.1vh;
        font-size: 14px;
      }
    }
  }
}
</style>
