<script>
import GPTAssistants from "@/views/components/GPTAssistants.vue"
// import GPTAssistants from "@/views/components/GPTAssistants.vue";
export default {
  components:{
    GPTAssistants:GPTAssistants
  },
  data() {
    return {
      visible:false,
      active:true,
      textarea1: '',
      textarea2: '',
      messages: [{
        ok: 0,
        id: '00000',
        sender: "ai",
        introduce: "Hello, here you can get all-round AI application services for the oil and gas industry. <br>What can I do for you today?",
        resource: "",
        url:'nan',
        isSendingMessage: 0,
        language:''
      },
      ],
    }
  },

  methods:{
    setVisible(){
      console.log("12312312asdfasdfd")
      // this.$parent.GptVisible=true
    },
    toLinkedin(){
      window.open("https://linkedin.com/company/oil-gas-gpts")
    },
    toGPT(){
      window.open("/gptAssistants")
    },
    toNews(){
      window.open("https://cn.oilgasinfoai.com/")
    },
    resizeTextarea() {
      const textarea = document.getElementById('text');
      if (textarea === null) {
        console.error('没有找到id为text的textarea元素');
        return;
      }
      const computedStyle = window.getComputedStyle(textarea);
      const height = computedStyle.height;
      const height1 = parseInt(height, 10);
      console.log("height",height)
      console.log("height",height1)


      const clientheight = window.innerHeight * 0.2;
      if (isNaN(height1) || height1 < clientheight) {
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
        if (this.newMessage.trim() === '') {
          textarea.style.height = '22px';
        }
        textarea.scrollTop = 0;
      }
    },
    sendMessage(){
      if (this.textarea2!=''){
        this.$emit("send1",this.textarea2)
        this.textarea2=""
      }
      document.body.style.overflowY="hidden"
    },
    handleInput() {
      // 计算输入框内容的行数
      this.currentRows = this.textarea2.split('\n').length;
      console.log("currentRows",this.currentRows)
    },
    NavigatTo(refName){
      console.log("this.$parent", this.$parent)
      // 使用 this.$refs 访问目标元素
      let distanceY = this.$parent.$refs[refName].offsetTop;
      console.log("distanceY", distanceY)
      console.log("window.scrollY", window.innerHeight)
      // 使用 scrollIntoView 方法滚动到该元素s
      window.scrollBy({
        top: distanceY-window.scrollY-window.innerHeight*0.08, // 向下滚动100像素
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<template>
  <div class="NavigationContainer">
  <div v-if="visible" style="position: fixed;width: 100%;height: 100%;background-color: rgba(128, 128, 128, 0.5);top:0;left: 0;z-index: 9999999">
    <div class="gptassistent" style="position: fixed;;top:50%;left: 50%;transform: translateY(-50%) translateX(-50%);width: 65%;height: 70%;overflow-y: scroll">
      <GPTAssistants></GPTAssistants>
    </div>
    <img @click="visible=false" style="height:2vw;width:2vw;position:fixed;right: 20vw;top: 20%;z-index: 30" src="@/assets/error.png">
  </div>
  <div>
  <div  class="inputContent">
    <el-input
        type="textarea"
        :autosize="{ minRows: 1, maxRows: 4}"
        placeholder="发送您要咨询的问题"
        resize="none"
        @keyup.enter.native="sendMessage"
        style="font-size: 1.2vw;overflow-y: hidden;font-family: 'Times New Roman';"
        v-model="textarea2">
    </el-input>
    <div @click="sendMessage" style="cursor: pointer;position: absolute;display: flex;right: 17vw; bottom: 0;height: 3vw;padding: 0vh 1vw; transform: translateY(-23%);border-radius: 20px;background: linear-gradient(to right, #117DD3, rgba(245,92,82,0.8));">
      <img  style="height: 2vw;width: 2vw;margin: auto" src="@/assets/up_arrow_white.png">
    </div>
  </div>
  <div  v-if="false"  class="Navigation">
    <div class="N1" style="position: relative;display: block" @click="toNews" >
      <div class="iconList" style="width: 100%;height: fit-content;display: flex;padding: 2vh 2vw;position: relative">
        <div class="icon" style="height: fit-content;width: fit-content;padding: 0.5vh;border: 2px solid #666d7d;border-radius: 9px;background-color: rgba(140, 147, 157, 0.5);margin-left: 0.5vw">
          <img style="height: 4vh;width: 4vh" src="@/assets/vector3.png">
        </div>
        <div class="icon" style="height: fit-content;width: fit-content;padding: 0.5vh;border: 2px solid #666d7d;border-radius: 9px;background-color: rgba(140, 147, 157, 0.5);margin-left: 0.5vw">
          <img style="height: 4vh;width: 4vh" src="@/assets/vector2.png">
        </div>
        <div class="icon" style="height: fit-content;width: fit-content;padding: 0.5vh;border: 2px solid #666d7d;border-radius: 9px;background-color: rgb(58,160,253);margin-left: 0.5vw">
          <img style="height: 4vh;width: 4vh" src="@/assets/vector1.png">
        </div>
        <div class="icon" style="height: fit-content;width: fit-content;padding: 0.5vh;border: 2px solid #666d7d;border-radius: 9px;background-color: rgba(140, 147, 157, 0.5);margin-left: 0.5vw">
          <img style="height: 4vh;width: 4vh" src="@/assets/Vector.png">
        </div>
        <img style="height: 8vh;width: 8vh;position:absolute;right: 1vw;top: 1vw" src="@/assets/right_circle.png">
      </div>
      <div style="text-align: left;font-size: 1.7vw;margin:1vh  0 0 2.5vw;font-weight: bolder">
        免费获取油气行业新闻资讯！
      </div>
<!--      <div style="padding: 0 0 0 2.5vw">-->
<!--        探索AI工具-->
<!--      </div>-->
<!--      <div style="height: 1.4vw;position: absolute;right: 1vw">-->
<!--        <img style="width: 1.4vw;" src="@/assets/more_btn_icon.png_black.png">-->
<!--      </div>-->
    </div>
    <div class="N1" id="N2" style="width: 100%;height: 100%;padding: 3vh 2vw;justify-content: space-between;" >
      <div style="font-size: 1.7vw;text-align: left;display: flex;align-items: center;">
        <div style="font-weight: bolder">
          探索AI行业最新动态
          <br>
          <span style="font-size: 1.2vw;color: gray;font-weight: unset;    font-family: fangsong;
    font-style: italic;">即刻关注！永久免费！</span>
        </div>
      </div>
      <div style="width: 20vh;height: 20vh;padding: 1vh;border: 2px solid gray;border-radius: 9px;background-color: rgba(140, 147, 157, 0.5)">
        <img src="@/assets/wechat.jpg">
      </div>
<!--      <div style="margin: auto 1vw auto 1vw;width: 1.8vw;height: 1.8vw">-->
<!--        <img style="width: 1.8vw;height: 1.8vw" src="@/assets/linkin_blue.png">-->
<!--      </div>-->
<!--      <div >-->
<!--        Follow Us,Free Use-->
<!--      </div>-->
    </div>
  </div>
  <div v-if="false"   class="bottomContent">
    <div class="item"  @click="NavigatTo('consultMove')" >
      <div class="content1" >智慧油田</div>
<!--      <div class="content2">获取全场景的数智油田解决方案</div>-->
      <div class="content3" >
        <img src="@/assets/more_btn_icon.png_black.png">
      </div>
    </div>
    <div class="item" @click="NavigatTo('professional')">
      <div class="content1" >定制化人工智能(AI)</div>
<!--      <div class="content2">定制人工智能场景油气行业应用</div>-->
      <div class="content3" >
        <img src="@/assets/more_btn_icon.png_black.png">
      </div>
    </div>
    <div class="item" @click="NavigatTo('software')">
      <div class="content1" >软件产品</div>
<!--      <div class="content2">全场景的数智油田软件及产品开发</div>-->
      <div class="content3" >
        <img src="@/assets/more_btn_icon.png_black.png">
      </div>
    </div>
    <div class="item" @click="NavigatTo('mediaMove')">
      <div class="content1" >智能多媒体</div>
<!--      <div class="content2">提升您品牌在数智浪潮中的传播力</div>-->
      <div class="content3" >
        <img src="@/assets/more_btn_icon.png_black.png">
      </div>
    </div>
  </div>
  <div  class="bottomContent" style="display: flex;    justify-content: space-between;">
    <div style="display: flex;border: 1px solid #DCDFE6;border-radius: 15px" class="bottom1">
        <div class="N1" style="position: relative;display:flex;width: 33vw;padding: 3vh 1vw;height: 14vw;" @click="toNews" >
          <div style="display: flex;flex-direction: column;justify-content: space-between;align-items: center;width: 70%;padding: 1vh 0 6vh ">
            <div style="text-align: center;font-size: 1.7vw;font-weight: bolder;color: white">
              洞察油气行业前沿资讯<br>把握市场先机
            </div>
            <div style="display: flex">
              <div style="position: relative;background-color: white;width: 15vw;border-radius: 9px;padding: 1.5vh 1vw;margin: 3vh auto auto;cursor: pointer">
                <span style="color:black;font-size: 1.1vw">获取新闻资讯</span>
                <img style="height: 1vw;width: 1vw;margin-left: 1vw;position: absolute;right: 1vw;top:50%;transform: translateY(-50%)" src="@/assets/more_btn_icon.png_black.png">
              </div>
            </div>
          </div>
          <div style="width: 30%;padding: 4vh 0 0 ">
            <img style="height: 5.5vw;width: 5.5vw" src="@/assets/wechat.jpg">
            <div style="display: flex;">
              <div style="color: white;text-align: left;font-size: 0.9vw;margin: auto">
                即刻关注<br>限时免费
              </div>
            </div>
          </div>
        </div>
<!--        <div class="N1" id="N2" style="width: 20vw;height: 100%;padding: 3vh 2vw;justify-content: space-between;" >-->
<!--          <div style="font-size: 1.3vw;text-align: left;;align-items: center;">-->
<!--            <div style="font-weight: bolder;text-align: center;">-->
<!--              免费获取AI行业<br>最新动态-->
<!--              <br>-->

<!--            </div>-->
<!--          </div>-->
<!--          <div style="display: flex;width: 100%;height: fit-content">-->
<!--            <div style="margin: auto;width: 12vh;height: 12vh;">-->
<!--&lt;!&ndash;            <div style="margin: auto;width: 13vh;height: 13vh;padding: 1vh;border: 2px solid gray;border-radius: 9px;background-color: rgba(140, 147, 157, 0.5)">&ndash;&gt;-->
<!--              <img src="@/assets/wechat.jpg">-->
<!--            </div>-->

<!--          </div>-->
<!--          <div style="font-size: 0.9vw;color: gray;font-weight: unset;    font-family: Sora;margin-top: -0.2vh">扫码关注</div>-->
<!--        </div>-->
    </div>
    <div style="display: block">
      <div style="display: flex">
        <div class="parallelogram" style="border-bottom: 2px solid #117dd3;"></div>
        <div style="padding: 0 1vw;border-bottom: 2px solid #117dd3;margin-bottom: 1px">安迅产品及服务</div>
      </div>
      <div style="display: grid;grid-template-columns: minmax(0,1fr) minmax(0,1fr);height: 8vw;gap: 1vh;margin-top: 2vw;">
        <div class="item" style="    background-color: transparent;;padding: 1.4vh 0.5vw;display:flex;align-items: center;"  @click="NavigatTo('consultMove')" >
          <img style="height: 1.5vw;width: 1.5vw" src="@/assets/news111.png">
          <div class="content1" style="margin: 0 1vw">智慧油田</div>
          <!--      <div class="content2">获取全场景的数智油田解决方案</div>-->
          <div class="content3" >
            <img src="@/assets/more_btn_icon.png_black.png">
          </div>
        </div>
        <div class="item" style="    background-color: transparent;;padding: 1.4vh 1vw 1.4vh 0.5vw;display:flex;align-items: center;" @click="NavigatTo('professional')">
          <img style="height: 1.5vw;width: 1.5vw" src="@/assets/news112.png">
          <div class="content1" style="margin: 0 1vw">定制化人工智能</div>
          <!--      <div class="content2">定制人工智能场景油气行业应用</div>-->
          <div class="content3" >
            <img src="@/assets/more_btn_icon.png_black.png">
          </div>
        </div>
        <div class="item" style="    background-color: transparent;;padding: 1.4vh 0.5vw;display:flex;align-items: center;" @click="NavigatTo('software')">
          <img style="height: 1.5vw;width: 1.5vw" src="@/assets/news113.png">
          <div class="content1" style="margin: 0 1vw">软件产品</div>
          <!--      <div class="content2">全场景的数智油田软件及产品开发</div>-->
          <div class="content3" >
            <img src="@/assets/more_btn_icon.png_black.png">
          </div>
        </div>
        <div class="item" style="    background-color: transparent;;padding: 1.4vh 0.5vw;display:flex;align-items: center;" @click="NavigatTo('mediaMove')">
          <img style="height: 1.5vw;width: 1.5vw" src="@/assets/news114.png">
          <div class="content1" style="margin: 0 1vw">智能多媒体</div>
          <!--      <div class="content2">提升您品牌在数智浪潮中的传播力</div>-->
          <div class="content3" >
            <img src="@/assets/more_btn_icon.png_black.png">
          </div>
        </div>
      </div>

    </div>

  </div>
  <transition appear name="expand">
    <div style="position: absolute;bottom: -6vh;height: 2vw;width: 2vw;left: 49%">
      <div style="position: relative;height: 2vw;width: 2vw">
        <img src="@/assets/arrow.png" class="animated-element" style="width: 1.5vw;height: 1.5vh;position: absolute">
        <img src="@/assets/arrow.png" class="animated-element2" style="width: 1.5vw;height: 1.5vh;position: absolute">
        <img src="@/assets/arrow.png" class="animated-element3" style="width: 1.5vw;height: 1.5vh;position: absolute">
        <img src="@/assets/arrow.png" class="animated-element4" style="width: 1.5vw;height: 1.5vh;position: absolute">
      </div>
    </div>

  </transition>
</div>

</div>
</template>

<style scoped lang="scss">
.parallelogram {
  width: 3vw;
  height: 2vw;
  background-color: #117dd3;
  transform: skew(20deg);
  //margin: 50px auto;
}
.NavigationContainer{
  width: 100%;
  height: 66vh;
  position: relative;
  font-family: Sora;
  .Navigation{
    width: 100%;
    height: 23vh;
    //background-color: white;
    display: grid;
    padding: 0 10vw;
    margin-top: 10vh;
    gap: 5vw;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr);

    .N1 {
      min-height: 0;
      height: 100%;
      display: flex;
      cursor: pointer;
      //color: black;
      //border:1px solid gray;
      background-color: rgb(244, 244, 244);
      border: solid 1px black;
      //margin: 1vh 1vw;
      //padding: 3vh 1vw 7vh 3vw;
      font-size: 1.2vw;
      border-radius: 15px;
      color: black;
      //color: white;
      text-align: center;
      //display: grid;
      align-items: center;
      //background-image: url("../../assets/homebanner1.jpg");
      background-size: cover;
    }
    #N2{
      //background-image: url("../../assets/homebanner2.jpg");
    }
    .title1{
      font-size: 2vw;
      font-weight: bolder;
      color: #6262f4;
      //font-family: initial;
      text-align: left;
    }
    .title2{
      color: #000000;
      font-size: 1vw;
      //font-weight: bolder;
      //font-family: "Times New Roman";
      text-align: left;

    }
  }
  .inputContent{
    font-size: 2vw;
    padding:2vh 16vw 0 ;
    height: fit-content;
    border-radius: 9px;
    position: relative;
    ::v-deep .el-textarea__inner{
      background-color: white;
      //background-color: rgba(80, 80, 80, 0.3);
      overflow-y: hidden;
      color: black;
      //color: white;
      border-radius: 20px;
      border: 1px solid #122277;
      min-height: 4vw;
      //font-family: "Times New Roman";
      padding: 4vw 5vw 4vw 2vw;
      //min-height: 20vh !important;

    }
    ::v-deep .el-textarea__inner::placeholder{
      //font-style: italic;
      color: gray;
    }
  }
  .bottomContent{
    .bottom1{
      background-image: url("../../assets/Frame1171277103.png");
      background-size: cover;
    }
    display: grid;
    width: 100%;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    //position: absolute;
    //bottom: 19vh  ;
    margin-top:8vh;
    padding:0 16vw;
    //padding:0 11vw;
    font-size: 1.2vw;
    font-weight: bolder;
    align-items: center;
    height: fit-content;
    .item{
      height: 100%;
      position: relative;
      padding: 2vh 2.5vw;
      border: 2px solid #dcdfe6;
      text-align: left;
      font-weight: normal;
      cursor: pointer;
      //margin-left: 1vw;
      background-color: rgb(242, 242, 242);
      //padding: 1vh 1vw;
      border-radius: 28px;
      color:black;
      //color:white;
      .content1{
        font-size: 1vw;

      }
      .content2{
        font-size: 1vw;
        color: gray;
        margin-top: 2vh;
      }
      .content3{
        position: absolute;top: 50%;transform:translateY(-50%);right: 1vw;
        img {
          width: 0.8vw;
          height: 0.8vw;
        }

      }
    }
  }
  .animated-element{
    //height: 10vh;
    animation: jiantou 3s linear infinite 0S;
  }
  .animated-element2{
    //height: 10vh;
    animation: jiantou 3s linear infinite 0.8s;
  }
  .animated-element3{
    //height: 10vh;
    animation: jiantou 3s linear infinite 2s;
  }
  .animated-element4{
    //height: 10vh;
    animation: jiantou 3s linear infinite 3s;
  }
  .expand-enter-active {
    animation: bounceIn 5s linear both;
  }
  .expand-height-enter-active,
  .expand-height-leave-active {
    transition: height 3s ease;
  }

  .expand-height-enter-from,
  .expand-height-leave-to {
    height: 58vh;
  }

  .expanded {
    height: 75vh;
    overflow: hidden;
  }
  .communicate {
    height: 100%;
    width: 64vw;
    padding: 0 1vw;
    margin: 1.5vh auto 0 auto;
    background: transparent;
    font-size: 1.1vw;
    overflow-y: auto;
    overflow-x: hidden;
    //border: 5px solid black;
  }
  .communicate::-webkit-scrollbar-track-piece {
    background-color: #f8f8f800;
  }
  .communicate::-webkit-scrollbar {
    width: 6px;
    transition: all 2s;
  }
  .communicate::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    border-radius: 100px;
  }
  .communicate::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
  }
  .communicate::-webkit-scrollbar-corner {
    background-color: rgba(255, 255, 255, 0);
  }
}
</style>
