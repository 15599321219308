<script>
import {defineComponent} from "vue";
import headtip from "@/views/headTip.vue";
import consultTip from "@/components/consultTip.vue";

export default defineComponent({
  components: {consultTip, headtip},
  data(){
    return{
      dialogVisible: false,
      title: 'GPT Assistants',
    }

  },
  methods:{
    tohome(){
      window.open("/")
    },
    clickConsult(item) {

      console.log(item)
      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    hideDialog() {
      localStorage.setItem("skip", 0)
      this.dialogVisible = false;
    },

  }
})
</script>

<template>
<div class="assistantsContainer" style="z-index: 999">
<!--  <div class="topback" ref="topback"-->
<!--       :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed',top:'0'}">-->
<!--    <headtip :changeVisable="changeVisable" @showDialog="showPartner"></headtip>-->
<!--  </div>-->
<!--  <img @click="()=>{$parent.visible=false}" style="height:2vw;width:2vw;position:fixed;right: 2vw;top: 2vw" src="@/assets/error.png">-->
  <div class="title1">GPT助手</div>
  <div class="title2">让您一键即可访问主流人工智能工具模型</div>
  <div style="width: 100%;height: 2vw;display: flex;margin-top: 2vh">
    <div class="title2" style="display: flex;align-items: center;margin: auto">
      <img src="@/assets/kefu3.png" style="height: 2vw;width: 2vw">
      <span style="color:#f97420;">若想定制化的使用培训与开发,</span>
<!--      <div style="background-color: #1b1e76;color: white;padding: 0.1vh 0.5vw;border-radius: 9px;cursor: pointer;font-weight: bolder;" @click="clickConsult('Office GPTs')" >联系我们</div>-->
      <div style="background-color: #1b1e76;color: white;padding: 0.1vh 0.5vw;border-radius: 9px;cursor: pointer;font-weight: bolder;" @click="()=>{$parent.$parent.naviclickConsult('定制化AI工具')}" >联系我们</div>
    </div>
  </div>
  <div class="assistants" style="display: grid;grid-template-columns: minmax(0,1fr) minmax(0,1fr);padding: 0 4vw;position: relative">
    <a href="https://www.deepseek.com/" target="_blank">
      <div class="assistant" >
        <img  src="@/assets/deepseek.png">
        <div class="contentDesign">
          <div class="t1">DeepSeek</div>
          <div class="t2">
            DeepSeek 以混合专家架构等创新技术，实现低训练成本与高性能，通过开源策略赋能开发者，具备低延迟与高扩展性。
          </div>
        </div>
      </div>
    </a>
    <a href="https://www.doubao.com/" target="_blank">
      <div class="assistant">
        <img  src="@/assets/dou.png">
        <div class="contentDesign">
          <div class="t1">豆包</div>
          <div class="t2">
            豆包依托字节跳动先进技术与海量数据，语言理解精准、生成灵活，能给出简洁实用建议，支持多模态交互，满足多样需求。
          </div>
        </div>
      </div>
    </a>
    <a href="https://tongyi.aliyun.com/qianwen/" target="_blank">
      <div class="assistant">
      <img  src="@/assets/tong.png">
      <div class="contentDesign">
        <div class="t1">通义千问</div>
        <div class="t2">
          通义千问是阿里云自研大模型，基于海量数据训练，能实现多轮对话、文案创作、逻辑推理，还支持多模态理解与多语言交互。
        </div>
      </div>
    </div>
    </a>
    <a href="https://chatglm.cn/" target="_blank">
      <div class="assistant">
      <img  src="@/assets/zhi.png">
      <div class="contentDesign">
        <div class="t1">智谱清言</div>
        <div class="t2">
          智谱清言基于 ChatGLM 系列模型，经海量文本与代码预训练及有监督微调，能处理多领域复杂任务，还融入灵感大全模块助力用户提效 。
        </div>
      </div>
    </div>
    </a>
    <a href="https://xinghuo.xfyun.cn/" target="_blank">
      <div class="assistant">
        <img  src="@/assets/xun.png">
        <div class="contentDesign">
          <div class="t1">讯飞星火</div>
          <div class="t2">
            讯飞星火是科大讯飞研发的认知智能大模型，具备多维度能力，持续升级进化，广泛应用于教育、办公、汽车等领域 。
          </div>
        </div>
      </div>
    </a>
    <a href="https://hunyuan.tencent.com/" target="_blank">
      <div class="assistant">
        <img  src="@/assets/teng.png">
        <div class="contentDesign">
          <div class="t1">腾讯混元</div>
          <div class="t2">
            腾讯混元是腾讯全链路自研的大语言模型，有超千亿参数规模，具备多维度能力，注重安全隐私保护。

          </div>
        </div>
      </div>
    </a>
    <a href="https://home.tiangong.cn/" target="_blank">
      <div class="assistant">
        <img  src="@/assets/tian.png">
        <div class="contentDesign">
          <div class="t1">天工AI</div>
          <div class="t2">
            天工 AI 是由中国电信打造的大模型，融合海量数据与电信行业优势，行业赋能等场景表现出色，助力数字生活与产业升级。

          </div>
        </div>
      </div>
    </a>
    <a href="https://yiyan.baidu.com/" target="_blank">
      <div class="assistant">
        <img  src="@/assets/ge.png">
        <div class="contentDesign">
          <div class="t1">文心一言</div>
          <div class="t2">
            文心一言是百度基于文心大模型打造的知识增强大语言模型，知识储备丰富，能灵活进行自然语言交互，在各行业广泛赋能应用。
          </div>
        </div>
      </div>
    </a>
<!--    <img @click="tohome" style="height: 3vw;width: 3vw;position: absolute;top: -10vh;left: 16vw;cursor: pointer" src="@/assets/back1.png">-->
  </div>
  <consultTip
      :thisTitle="title"
      :dialogVisible="dialogVisible"
      @hideDialog="hideDialog"
  ></consultTip>
</div>
</template>

<style scoped lang="scss">
.assistantsContainer{
  width: 100%;
  background-color: rgba(237, 242, 253, 0.99);
  .topback {
    position: fixed;
    z-index: 9;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .title1{
    font-size: 2vw;
    margin: auto;
    padding: 2vh 0;
    text-align: center;
    font-weight: bolder;
  }
  .title2{
    font-size: 1.1vw;
    text-align: center;

  }
  .assistant{
    margin: 2vh;
    //padding: 4vh 3vw 4vh 2vw;
    padding: 2vh 3vw 0vh 2vw;
    display: flex;
    //border: 1px solid gray;
    background-color: white;
    border-radius: 9px;
    img{
      width: 3vw;
      height: 3vw;
    }
    .contentDesign{
      margin-left: 1vw;
      .t1{
        text-align: left;
        font-size: 1.2vw;
        font-weight: bolder;
      }
      .t2{
        text-align: left;
        margin-top: 1vh;
        font-size: 0.8vw;
        height: 5.5vw;
        line-height: 1.5;
      }
    }
  }
}

</style>
